import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(`transition: ${_ctx.durationMs}ms ${_ctx.transFunction}; ${
      _ctx.isShown ? _ctx.shownStyle : _ctx.hiddenStyle
    };`),
    onTransitionend: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShown ? _ctx.onShowEnd() : _ctx.onHideEnd()))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 36))
}
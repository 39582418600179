<template>
  <div
    class="credits dys-letter"
    id="creditsContainer"
    @wheel="manualScroll"
    :style="
      isShown
        ? 'background: black;'
        : 'display:none; background: transparent; color: transparent; pointer-events: none; overflow: hidden'
    "
  >
    <div
      class="go-back"
      @click="onCreditsClose()"
      :style="isShown ? '' : 'color: transparent'"
    >
      Go back
    </div>
    <h1 style="margin-top: 10em; margin-bottom: 12em">Diapason of Fate</h1>
    <div v-for="(element, heading) in data" :key="heading">
      <h2>{{ heading }}</h2>
      <div
        class="heading-container"
        v-for="(elem, elementName) in element"
        :key="elementName"
      >
        <div v-if="(elementName as string).includes('SINGLE')" class="single">
          <a
            class="credit-link"
            :style="isShown ? '' : 'color: transparent'"
            v-if="elem.indexOf('|') !== -1"
            :href="elem.substring(elem.indexOf('|') + 1)"
            >{{ elem.substring(0, elem.indexOf("|")) }}</a
          ><span v-else>{{ elem }}</span>
        </div>
        <span v-else>
          <span class="left">{{ elementName }}</span>
          <span class="right">
            <a
              class="credit-link"
              :style="isShown ? '' : 'color: transparent'"
              v-if="elem.indexOf('|') !== -1"
              :href="elem.substring(elem.indexOf('|') + 1)"
              >{{ elem.substring(0, elem.indexOf("|")) }}</a
            ><span v-else>{{ elem }}</span>
          </span>
        </span>
      </div>
    </div>
    <h1 style="margin-top: 10em; margin-bottom: 6em">
      Thanks for playing, have a nice day.
    </h1>
    <div
      :style="`margin-top: 3em; color: lightgray; margin-left: 20%; margin-right: 20%; transition: color 1s; ${
        isShown ? '' : 'color: transparent'
      }`"
    >
      2023 No copyright. Most of the assets used in this game are taken from
      internet without any authorization. If you want something to be removed,
      contact me on discord @leco4002. This is just for fun, I don't make any
      money with this.
    </div>
    <br />
  </div>
</template>

<script lang="ts">
import { ICredits } from "@/tools";
import { defineComponent } from "vue";
import creditsJson from "../credits.json";

export default defineComponent({
  name: "ClosingCredits",
  methods: {
    manualScroll() {
      clearTimeout(this.scrolling);
    },
  },
  props: {
    isShown: {
      required: true,
      type: Boolean,
      default: false,
    },
    onCreditsClose: {
      required: false,
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      data: creditsJson as ICredits,
      scrolling: 0,
    };
  },
  watch: {
    isShown(current, old) {
      if (current && !old) {
        const div = document.getElementById(
          "creditsContainer"
        ) as HTMLDivElement | null;

        if (div === null) return;

        clearTimeout(this.scrolling);

        // Instant scroll to top
        div.classList.add("no-smooth");
        div.scrollTo(0, 0);
        div.classList.remove("no-smooth");

        let pageScroll = () => {};

        pageScroll = () => {
          div.scrollBy(0, 7);
          this.scrolling = setTimeout(pageScroll, 100);
          if (div.scrollTop === (div as any).scrollTopMax) {
            clearTimeout(this.scrolling);
          }
        };

        this.scrolling = setTimeout(pageScroll, 0);
      }
    },
  },
});
</script>

<style scoped lang="scss">
.heading-container {
  width: 100%;
  font-size: 20px;
}

.left {
  padding-right: 1%;
  text-align: right;
  display: inline-block;
  width: 49%;
}

.right {
  padding-left: 1%;
  display: inline-block;
  width: 49%;
  margin-top: 0.2em;
}

.single {
  text-align: center;
  margin-top: 0.2em;
}

.credits {
  transition: 1s;
  position: absolute;
  left: 0;
  right: 0;
  color: white;
  height: 100%;
  top: 0;
  overflow-y: scroll;
  z-index: 1001;
  scroll-behavior: smooth;
}

.no-smooth {
  scroll-behavior: inherit;
}

h1 {
  font-size: 40px;
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

h2 {
  font-size: 30px;
  text-align: center;
  margin-top: 3em;
  text-decoration: underline;
}

.credit-link {
  transition: color 1s;
  color: #5a86ff;

  &:visited {
    color: #af5aff;
  }
}

.go-back {
  position: fixed;
  right: 15%;
  top: 10%;
  font-size: 30px;
  color: lightgray;
  user-select: none;
  cursor: pointer;
  transition: color 1s, letter-spacing 0.3s;

  &:hover {
    letter-spacing: 2px;
  }
}
</style>

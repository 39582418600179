<template>
  <canvas id="combat-canvas"></canvas>
  <div :class="`main ${bools.dyslexic ? 'dyslexic dys-size' : ''}`">
    <router-view />
    <div
      :style="`display: ${bools.showDebug ? 'inherit' : 'none'}`"
      class="debug"
    >
      Select scene :
      <select
        tabindex="-1"
        :disabled="bools.sentenceExecuting"
        @change="
          (e) => {
            DEBUG_loadScene(e.target);
          }
        "
      >
        <option>Select a scene</option>
        <option
          v-for="sceneName in Object.keys(scenes)"
          :value="sceneName"
          :key="sceneName"
        >
          {{ sceneName }}
        </option>
      </select>
      <select tabindex="-1">
        <option>Conditions</option>
        <option
          v-for="condition in conditions"
          :value="condition"
          :key="condition"
        >
          {{ condition }}
        </option>
      </select>
      sentenceMutex.isLocked :
      {{ sentenceSemaphore.isLocked() }}
      <br />
      <button tabindex="-1" @click="conditions = []">Clear conditions</button>
      <button
        tabindex="-1"
        :disabled="bools.sentenceExecuting"
        @click="
          () => {
            if (strings.currentScene != null && !bools.sentenceExecuting)
              loadScene(strings.currentScene);
          }
        "
      >
        Reload scene
      </button>
      <button tabindex="-1" @click="shakeImage">Shake</button>
      <button tabindex="-1" @click="skipSentence">Skip sentence</button>
      <button
        tabindex="-1"
        @click="
          audioSources.music_background.setCurrentTime(
            audioSources.music_background.getDuration()
          );
          audioSources.music_background.pause();
        "
      >
        Skip battle
      </button>
      <button tabindex="-1" @click="++goldenNotes">++goldenNotes</button>
      <button tabindex="-1" @click="--goldenNotes">--goldenNotes</button>
      <button tabindex="-1" @click="++bloodNotes">++bloodNotes</button>
      <button tabindex="-1" @click="--bloodNotes">--bloodNotes</button>
      <button tabindex="-1" @click="resetSave">Reset save</button>
      <button tabindex="-1" @click="setAllNotif('0')">Clear all notif</button>
      <button tabindex="-1" @click="setAllNotif('1')">
        Re-activate all notif
      </button>
      <button tabindex="-1" @click="setAllBossesConditions()">
        Set all boss fight conditions
      </button>
      <br />
      <!-- Display all strings -->
      <span
        v-for="(stringText, stringName) in strings"
        :key="stringName"
        style="margin-right: 10px; min-width: 400px; display: inline-block"
      >
        {{ stringName }}: "{{ stringText }}"
      </span>
      <span id="visits"></span>
      <br />
      <!-- Display all booleans -->
      <label
        v-for="(isTrue, boolName) in bools"
        :key="boolName"
        style="margin-right: 10px; min-width: 180px; display: inline-block"
      >
        {{ boolName }}
        <input
          :checked="isTrue"
          type="checkbox"
          tabindex="-1"
          @click="bools[boolName] = !isTrue"
        />
      </label>
      <div style="color: red; font-size: 25px">
        Press F4 to toggle debug mode
      </div>
    </div>
    <div class="screen-too-small" v-if="bools.screenTooSmall">
      Your screen is too small.
      <br />
      Hold CTRL and use the mouse wheel or +/- keys to zoom in or out on the UI.
      <br />
      (minimum 1350px wide)
    </div>
    <video
      tabindex="-1"
      preload="auto"
      :volume="musicVolume"
      disablePictureInPicture
      controlsList="nodownload"
      id="horrible-transition"
      class="horrible-transition"
      src="cinematics/horrible_transition.mp4"
    ></video>
    <div
      id="image-panel"
      :class="`image-container`"
      :style="`height: ${bools.showCinematic ? 28 : 19}em`"
    >
      <!-- Image must be 5:2 ratio-->
      <ShowHide
        :is-shown="bools.showImage"
        :duration-ms="1000"
        :on-hide-end="onImageHideEnd"
        :on-show-end="onImageShowEnd"
        shown-style="height: 100%"
        hidden-style="filter: brightness(0); height: 100%"
      >
        <div class="scene-txt dys-letter dys-size0">
          {{ currentImageClean }}
        </div>
        <img
          :src="`img/scenes/${strings.currentImage}.${
            strings.currentImage.startsWith('the_singer') ? 'gif' : 'jpg'
          }`"
          class="scene-img"
          @load="onImageLoad"
        />
      </ShowHide>
      <ShowHide :is-shown="bools.showCharacter" :duration-ms="1000">
        <img
          :src="`img/characters/${strings.currentCharacter}.png`"
          :alt="`character ${strings.currentCharacter}`"
          :class="`onTop-img ${
            bools.characterWiggle ? 'character-wiggle' : ''
          }`"
        />
      </ShowHide>
      <ShowHide
        :is-shown="bools.isLoading"
        trans-function="ease-in"
        :duration-ms="bools.isLoading ? 5000 : 200"
        class="loading"
      >
        Loading...
      </ShowHide>
      <ShowHide
        :is-shown="bools.showCinematic"
        :duration-ms="1000"
        hidden-style="opacity: 0"
        :on-hide-end="onCinematicHideEnd"
        :on-show-end="onCinematicShowEnd"
      >
        <video
          tabindex="-1"
          @pause="onCinematicPause"
          @canplaythrough="onCinematicLoad"
          disablePictureInPicture
          controlsList="nodownload"
          :volume="musicVolume"
          id="cinematic"
          class="cinematic"
          :src="`cinematics/${strings.currentCinematic}.mp4`"
        >
          <track
            kind="captions"
            srclang="en"
            :src="`cinematics/${strings.currentCinematic}.vtt`"
          />
          <a :href="`cinematics/${strings.currentCinematic}.mp4`">
            Download video
          </a>
        </video>
      </ShowHide>
    </div>
    <ShowHide
      :is-shown="bools.showBattle"
      trans-function="linear"
      :duration-ms="1000"
      :on-hide-end="onBattleHideEnd"
      :on-show-end="onBattleShowEnd"
      shown-style="height: 17em;"
      hidden-style="height: 0; margin-top: 0; opacity: 0;"
    >
      <CombatCanvas
        :dyslexic="bools.dyslexic"
        :fx-volume="soundEffectsVolume"
        :set-h-p="(x: number) => { if (x <= 0) {
          gameover();
        } }"
        :update-stuff="(stuff: any) => {
          bools.showBattle = stuff.showBattle;
          bools.battleActive = stuff.battleActive;

          loadScene(stuff.nextScene);
        }"
        :enable-screen-shake="bools.screenShake"
        :invincible="bools.invincible"
        :show-debug="bools.showDebugBattle"
        :battle-name="strings.currentBattle"
        :battle-time="battleTime"
        :override-display-singer="overrideDisplaySinger"
        :is-active="bools.battleActive"
        :reload-request="bools.reloadRequest"
        :audio-sources="audioSources"
      />
    </ShowHide>
    <ShowHide
      trans-function="linear"
      :is-shown="bools.showText"
      :duration-ms="1000"
      hidden-style="height: 0em; padding-top: 0; padding-bottom: 0; opacity: 0"
      shown-style="height: 6.8em"
      class="text-container dys-line dys-quickfix"
      :class="
        bools.sentenceExecuting
          ? bools.dyslexic
            ? 'text-skippable-dys'
            : 'text-skippable'
          : ''
      "
      @click="skipSentence"
      id="textContainer"
    >
      <span
        v-for="s in sentences"
        :key="s.text"
        :class="`${bools.dyslexic ? 'dyslexic' : ''} ${
          s.noAnim ? '' : 'typewriter'
        } ${s.customClass}`"
        :style="`
			--num-char:${s.text.length};
			--time-per-letter: ${s.timePerLetter}s;
			--color: ${s.customColor};
			${s.noAnim ? 'color: ' + s.customColor : ''};
			font-family: '${s.overrideFont !== '' ? s.overrideFont : strings.currentFont}`"
        @animationend="animatedSentenceEnd"
      >
        {{ s.text }}<br v-if="s.newLine" />
      </span>
      <div
        v-if="bools.textSkippable"
        :class="`${bools.dyslexic ? 'dyslexic' : ''} text-skip-info ${
          bools.skipFlicker ? 'text-skip-flicker' : ''
        }`"
      >
        CTRL or click to continue
      </div>
    </ShowHide>
    <ShowHide
      class="choice-container"
      hidden-style="height: 0em;"
      shown-style="height: 10em"
      :is-shown="bools.showChoices"
      :duration-ms="1000"
      trans-function="linear"
    >
      <div
        v-for="choice in choices"
        :key="choice.text"
        :style="choices.length === 1 ? 'width: 100%' : ''"
      >
        <button
          :tabindex="!bools.showChoices || bools.showSettings ? -1 : 0"
          @click="onClickChoice(choice)"
          @mouseenter="
            () => {
              if (!bools.sentenceExecuting) audioSources.effects_hover.play();
            }
          "
          :class="` ${bools.dyslexic ? 'dyslexic dys-size dys-letter' : ''} ${
            bools.choicesInvisible ? 'invisible' : ''
          } ${
            bools.sentenceExecuting
              ? bools.dyslexic
                ? 'executing-text-dys'
                : 'executing-text'
              : ''
          }`"
        >
          <div class="choice-text">
            {{ choice.text }}
          </div>
          <div
            class="visited-label"
            v-if="choice.visited && !bools.choicesInvisible"
          >
            Seen
          </div>
        </button>
      </div>
    </ShowHide>
    <div class="bottom-bar">
      <ShowHide
        :is-shown="bools.showMusic"
        :duration-ms="1000"
        :on-hide-end="onMusicHideEnd"
        :on-show-end="onMusicShowEnd"
      >
        <span class="music-display dys-size2 dys-letter">
          <img src="img/icons/musical-note.png" />
          {{ strings.currentMusic }}
        </span>
      </ShowHide>
      <span class="notes-panel">
        <span :class="`notes-num ${bloodNotes <= 0 ? 'notes-invisible' : ''}`"
          ><img src="img/icons/blood_note.png" /> blood notes:
          {{ bloodNotes }}</span
        >
        <span :class="`notes-num ${goldenNotes <= 0 ? 'notes-invisible' : ''}`"
          ><img src="img/icons/golden_note.png" /> golden notes:
          {{ goldenNotes }}</span
        >
      </span>
      <button
        :tabindex="!bools.showChoices || bools.showSettings ? -1 : 0"
        class="options dys-size2"
        @click="onClickSettings()"
      >
        Settings<img src="img/icons/cog.png" />
        <img
          :style="`${isThereANotif() ? '' : 'display:none'}`"
          src="img/icons/notification_alt.png"
          class="notification notification-options"
        />
      </button>
    </div>
    <ClosingCredits
      :is-shown="bools.showCredits"
      :style="!bools.showCredits ? 'user-select: none' : ''"
      :on-credits-close="() => (bools.showCredits = false)"
    />
    <VueFinalModal
      v-model="showGotNote"
      :overlay-class="`ephemeral-prompt-overlay ${
        bools.dyslexic ? 'dyslexic' : ''
      }`"
      content-class="ephemeral-prompt-content"
      style="cursor: pointer"
    >
      <span :style="`color: ${isBloodNote() ? '#e14040' : '#d4af37'}`"
        >{{ isBloodNote() ? "Blood note" : "Golden note" }} obtained</span
      >
      <img
        :src="`img/icons/${isBloodNote() ? 'blood_note' : 'golden_note'}.png`"
      />
      <div>Click anywhere to continue</div>
    </VueFinalModal>
    <VueFinalModal
      v-model="showGameover"
      overlay-class="ephemeral-prompt-overlay"
      content-class="ephemeral-prompt-content"
      style="cursor: pointer"
    >
      <span style="color: #e14040">GAME OVER</span>
      <p style="font-size: 25px; color: lightgray; margin-bottom: 20px">
        You can do it!
      </p>
      <div>Click anywhere to go to last checkpoint</div>
      <div>Press 'R' to retry</div>
    </VueFinalModal>
    <VueFinalModal
      v-model="showEpilepsyWarning"
      overlay-class="ephemeral-prompt-overlay"
      content-class="ephemeral-prompt-content"
      style="cursor: pointer"
    >
      <span style="color: #e14040">Photosensitivity warning</span>
      <p style="font-size: 25px; color: lightgray; margin-bottom: 20px">
        A small percentage of people may experience seizures when exposed to
        certain lights, patterns or images, even with no history of epilepsy or
        seizures.
      </p>
      <div style="animation: none">Click anywhere to continue</div>
    </VueFinalModal>
    <VueFinalModal
      v-model="showGot3Notes"
      overlay-class="ephemeral-prompt-overlay"
      content-class="ephemeral-prompt-content"
      style="cursor: pointer"
    >
      <span style="color: purple">Got 3 golden notes !</span>
      <p style="font-size: 25px; color: lightgray; margin-bottom: 20px">
        You can now go to the chord gate and break the seal
      </p>
      <div>Click anywhere to continue</div>
    </VueFinalModal>
    <VueFinalModal
      v-model="showGameEnd"
      overlay-class="ephemeral-prompt-overlay"
      content-class="ephemeral-prompt-content"
      style="cursor: pointer"
    >
      <span>Thanks for playing!</span>
      <p style="font-size: 25px; color: lightgray; margin-bottom: 20px">
        Chapter 3 will be released soon™
      </p>
      <div>Click anywhere to go back to begining</div>
      <span style="font-size: 40px">or</span>
      <div>Don't click anywhere and listen to this sick saxophone solo</div>
    </VueFinalModal>
    <VueFinalModal
      v-model="bools.showSettings"
      overlay-class="settings-overlay"
      content-class="settings-content"
    >
      <div class="title-bar">
        <div class="title dys-letter dys-line">Settings</div>
        <button
          class="close-button-container"
          @click="bools.showSettings = false"
        >
          <img class="close-button" src="img/icons/cross.png" />
        </button>
      </div>
      <div class="dys-line dys-letter">
        <h2 style="text-decoration: underline; margin-bottom: 10px">Audio</h2>
        <div>
          Master volume {{ Math.round(masterVolume * 100) }}%
          <input
            type="range"
            min="0"
            max="100"
            :value="masterVolume * 100"
            class="horizontal-slider"
            @wheel="onWheelMasterVolume"
            @change="onMasterVolumeChange"
          />
        </div>
        <div>
          Music volume {{ Math.round(musicVolume * 100) }}%
          <input
            type="range"
            min="0"
            max="100"
            :value="musicVolume * 100"
            class="horizontal-slider"
            @wheel="onWheelMusicVolume"
            @change="onMusicVolumeChange"
          />
        </div>
        <div>
          Sound effects volume {{ Math.round(soundEffectsVolume * 100) }}%
          <input
            type="range"
            min="0"
            max="100"
            :value="soundEffectsVolume * 100"
            class="horizontal-slider"
            @wheel="onWheelSoundEffectsVolume"
            @change="onSoundEffectsVolumeChange"
          />
        </div>
        <h2 style="text-decoration: underline">Accessibility</h2>
        <div style="margin-bottom: 15px">
          Show subtitles during cinematics
          <label class="switch">
            <input
              type="checkbox"
              @click="onCinematicCaptionsToggle()"
              :checked="bools.showCinematicCaptions"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div style="margin-bottom: 15px">
          Use dyslexic font
          <label class="switch">
            <input
              type="checkbox"
              @click="onDyslexicToggle()"
              :checked="bools.dyslexic"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div style="margin-bottom: 15px">
          Enable screenshake
          <label class="switch">
            <input
              type="checkbox"
              @click="onScreenShakeToggle()"
              :checked="bools.screenShake"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div style="margin-bottom: 15px">
          Visual help (for color vision deficiency)
          <label class="switch">
            <input
              type="checkbox"
              @click="onVisualHelpToggle()"
              :checked="getVisualHelp()"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div style="margin-bottom: 15px">
          Invincibility
          <label class="switch">
            <input
              type="checkbox"
              @click="onInvincibleToggle()"
              :checked="bools.invincible"
            />
            <span class="slider round"></span>
          </label>
        </div>

        <h2 style="text-decoration: underline; margin-bottom: 10px">
          Miscellaneous
        </h2>

        <div style="margin-bottom: 10px">
          Hold CTRL and use the mouse wheel or +/- keys to zoom in or out on the
          UI.
        </div>

        <div>
          <button
            :class="`credits-button ${bools.dyslexic ? 'dyslexic' : ''}`"
            :disabled="strings.currentScene === null || bools.sentenceExecuting"
            @click="startChapter(1)"
            @mouseover="clearNotif('startChapter1')"
          >
            Start at chapter 1
            <img
              :style="`${
                notifications.get('startChapter1') === '1' ? '' : 'display:none'
              }`"
              src="img/icons/notification_alt.png"
              class="notification notification-button"
            />
          </button>
          <button
            :class="`credits-button ${bools.dyslexic ? 'dyslexic' : ''}`"
            :disabled="strings.currentScene === null || bools.sentenceExecuting"
            @click="startChapter(2)"
            @mouseover="clearNotif('startChapter2')"
          >
            Start at chapter 2
            <img
              :style="`${
                notifications.get('startChapter2') === '1' ? '' : 'display:none'
              }`"
              src="img/icons/notification_alt.png"
              class="notification notification-button"
            />
          </button>
          <button
            :class="`credits-button ${bools.dyslexic ? 'dyslexic' : ''} wip`"
            :disabled="true"
            title="Coming soon™"
          >
            Start at chapter 3
            <img src="img/icons/in_progress.png" class="wip_stamp" />
          </button>
        </div>

        <div>
          <button
            :class="`credits-button ${
              bools.dyslexic ? 'dyslexic' : ''
            } reset-progress`"
            @click="resetSave"
          >
            Reset progress
          </button>
        </div>
        <div>
          <button
            :class="`credits-button ${bools.dyslexic ? 'dyslexic' : ''}`"
            @click="
              bools.showCredits = true;
              bools.showSettings = false;
            "
          >
            Show credits
          </button>
        </div>

        <span>{{ numUniqueVisits }} unique visits</span>

        <br />

        <a
          href="https://leandro4002.itch.io/diapason-of-fate"
          class="itch-link-container"
          target="_blank"
        >
          <img width="17" src="img/icons/external.png" />
          <span class="itch-link">itch.io page</span>
        </a>

        <a
          href="https://saraivam.ch"
          class="itch-link-container"
          style="margin-left: 20px"
          target="_blank"
        >
          <img width="17" src="img/icons/external.png" />
          <span class="itch-link">personal website</span>
        </a>
      </div>
    </VueFinalModal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AudioSource } from "../AudioSource";
import scenesJson from "../scenes.json";
import battlesJson from "../battles.json";
import { Semaphore } from "async-mutex";
import { IBattles, ISentence, IScenes, IChoice } from "../tools";
import CombatCanvas from "./CombatCanvas.vue";
import ShowHide from "./ShowHide.vue";
import ClosingCredits from "./ClosingCredits.vue";
import { Bullet } from "../Bullet";

// Default audio
const DEFAULT_SENTENCE_FX = "mechanical";
const SETTINGS_RANGE_FORCE = 0.01;

const BLOOD_NOTES_CHARACTERS = [
  "legionary_violin",
  "cone_man",
  "corrupted_violin_fencer",
]; // optional bosses
const NO_NOTES_CHARACTERS = [
  "heavy_tuba_gunner",
  "heavy_tuba_gunner_pissed_of",
  "tuba_defender",
  "king_tuba_2",
  "tuba_angel",
]; // final boss of chapter

const NOTIF_LIST = ["startChapter1", "startChapter2"];
type NotifType = "0" | "1";

export default defineComponent({
  name: "PageMain",
  components: {
    CombatCanvas,
    ShowHide,
    ClosingCredits,
  },
  computed: {
    currentImageClean(): string {
      return this.capitalizeFirstLetter(
        this.strings.currentImage === undefined
          ? ""
          : this.strings.currentImage.replaceAll("_", " ")
      );
    },
  },
  data() {
    return {
      notifications: new Map<string, NotifType>(),
      bloodNotes: 0,
      goldenNotes: 0,
      masterVolume: 1,
      musicVolume: 0.8,
      soundEffectsVolume: 0.8,
      audioSources: {
        music_background: new AudioSource("music/MUTE.ogg", true),
        effects_hover: new AudioSource("soundfx/hover.ogg", false, 4),
        effects_click: new AudioSource("soundfx/click.ogg", false, 5),
        effects_sentence: new AudioSource(
          `soundfx/sentence/${DEFAULT_SENTENCE_FX}.ogg`,
          true
        ),
        effects_bass: new AudioSource(`soundfx/bass.ogg`),
        effects_bassLong: new AudioSource(`soundfx/bass_long.ogg`),
        effects_gotGoldenNote: new AudioSource(`soundfx/got_golden_note.ogg`),
        effects_gotBloodNote: new AudioSource(`soundfx/got_blood_note.ogg`),
        effects_got3Notes: new AudioSource(`soundfx/got_3_notes.ogg`),
        effects_grenadeCountdown: new AudioSource(
          `soundfx/grenade_countdown.ogg`,
          false,
          15
        ),
        effects_grenadeExplosion: new AudioSource(
          `soundfx/grenade_explosion.ogg`,
          false,
          15
        ),
        effects_noteYeet: new AudioSource(`soundfx/note_yeet.ogg`, false, 10),
        effects_grenadeYeet: new AudioSource(
          `soundfx/grenade_yeet.ogg`,
          false,
          3
        ),
        effects_playerHurt: new AudioSource(`soundfx/player_hurt.ogg`),
        effects_playerParry: new AudioSource(
          `soundfx/player_parry.ogg`,
          false,
          2
        ),
        effects_parryDeny: new AudioSource(`soundfx/parry_deny.ogg`, false, 2),
        effects_colorDeny: new AudioSource(`soundfx/color_deny.ogg`, false, 2),
        effects_playerHeartBeat: new AudioSource(
          `soundfx/player_heart_beat.ogg`,
          true
        ),
        effects_gameover: new AudioSource(`soundfx/gameover.ogg`),
        effects_gateOpen: new AudioSource(`soundfx/gate_open.ogg`),
        effects_saxSolo: new AudioSource(`soundfx/sax_solo.ogg`),
        effects_chord1: new AudioSource(`soundfx/chord1.ogg`),
        effects_chord2: new AudioSource(`soundfx/chord2.ogg`),
        effects_chord3: new AudioSource(`soundfx/chord3.ogg`),
        effects_chord4: new AudioSource(`soundfx/chord4.ogg`),
        effects_chord5: new AudioSource(`soundfx/chord5.ogg`),
        effects_singerSpawn: new AudioSource(
          `soundfx/the_singer/the_singer_spawn.ogg`
        ),
        music_singerAveMaria: new AudioSource(
          `soundfx/the_singer/the_singer_ave_maria_echo.ogg`,
          true
        ),
      } as {
        [key: string]: AudioSource;
      },
      numUniqueVisits: 0,
      // Cannot be put in bools because of the watch()
      showGotNote: false,
      showGot3Notes: false,
      showGameEnd: false,
      showGameover: false,
      showEpilepsyWarning: false,
      bools: {
        showImage: true,
        showCharacter: false,
        showDebug: true,
        showDebugBattle: true,
        showSettings: false,
        showCinematic: false,
        showText: true,
        showChoices: true,
        showMusic: false,
        showBattle: false,
        showCredits: false,
        showCinematicCaptions: false,
        sentenceExecuting: false,
        choicesInvisible: false,
        characterWiggle: false,
        noDelayDebug: false,
        textSkippable: false,
        sentenceSkipped: false,
        skipFlicker: false,
        isLoading: false,
        battleActive: false,
        reloadRequest: false,
        screenTooSmall: false,
        dyslexic: false,
        screenShake: true,
        invincible: false,
        resetPressed: false,
        aboutToShowGameover: false,
      },
      strings: {
        currentCharacter: "gate",
        currentScene: "loading",
        currentBattle: "tuba_knight",
        currentImage: "_",
        currentCinematic: "empty",
        currentFont: "normal_text",
        currentMusic: "",
      },
      battleTime: 0,
      overrideDisplaySinger: false,
      overwriteMusic: null as string | null,
      battles: battlesJson as IBattles,
      scenes: scenesJson as IScenes,
      sentences: [] as ISentence[],
      choices: [] as IChoice[],
      conditions: [] as string[],
      bloodBosses: [] as string[],
      cinematicVideoTag: null as HTMLVideoElement | null,
      sentenceSemaphore: new Semaphore(1),
      onImageHideEnd: () => {},
      onImageShowEnd: () => {},
      onCinematicHideEnd: () => {},
      onCinematicShowEnd: () => {},
      onMusicHideEnd: () => {},
      onMusicShowEnd: () => {},
      onBattleHideEnd: () => {},
      onBattleShowEnd: () => {},
      onImageLoad: () => {},
      onCinematicLoad: () => {},
      skipPauseResolve: () => {},
      gotNoteResolve: () => {},
      got3NotesResolve: () => {},
      gameEndResolve: () => {},
      gameoverResolve: () => {},
      epilepsyWarningResolve: () => {},
    };
  },
  methods: {
    /* DEBUG */
    DEBUG_loadScene(target: any) {
      const sceneName = target.value;
      target.value = "Select a scene";
      this.loadScene(sceneName);
    },

    updateBloodNotes() {
      let nbBloodNotes = 0;

      for (let i = 0; i < BLOOD_NOTES_CHARACTERS.length; ++i) {
        if (this.bloodBosses.includes(BLOOD_NOTES_CHARACTERS[i])) {
          ++nbBloodNotes;
        }
      }

      this.bloodNotes = nbBloodNotes;
    },
    capitalizeFirstLetter(txt: string) {
      return txt.charAt(0).toUpperCase() + txt.slice(1);
    },
    getVisualHelp() {
      return Bullet.visualHelp;
    },
    async gameend(chapterFinished: number) {
      this.audioSources.effects_saxSolo.play();

      // Mark chapter as finished
      this.markChapterFinished(chapterFinished);

      // Wait for acknowledgment of game end
      this.showGameEnd = true;
      await new Promise<void>((resolve) => {
        this.gameEndResolve = resolve;
      });

      this.audioSources.effects_saxSolo.pause();

      this.conditions = [];

      // Remove truc
      localStorage.removeItem("save_conditions");
      localStorage.removeItem("save_goldenNotes");
      localStorage.removeItem("save_currentScene");

      this.loadScene("splashscreen");
      this.saveGame();
    },
    async gameover() {
      this.audioSources.effects_grenadeCountdown.pause();
      this.audioSources.effects_gameover.play();
      this.audioSources.effects_playerHeartBeat.pause();
      this.audioSources.music_background.pause();

      // Record game over on specific boss
      if (!this.bools.showDebug && !this.bools.showDebugBattle) {
        this.countHit(`gameover_${this.strings.currentBattle}`);
      }

      this.bools.aboutToShowGameover = true;

      // Wait so we don't miss click and skip prompt
      await new Promise<void>((resolve) =>
        setTimeout(() => {
          resolve();
        }, 1000)
      );

      this.bools.aboutToShowGameover = false;

      // Wait for user to close gameover prompt
      this.showGameover = true;

      if (this.bools.resetPressed) {
        this.bools.resetPressed = false;
        this.showGameover = false;
        return;
      }

      await new Promise<void>((resolve) => {
        this.gameoverResolve = resolve;
      });

      if (this.bools.resetPressed) {
        this.bools.resetPressed = false;
        this.showGameover = false;
        return;
      }

      this.bools.showBattle = false;
      this.bools.battleActive = false;

      const gameOverScene =
        this.scenes[this.strings.currentScene].gameOverScene;

      if (gameOverScene === undefined) {
        // This should never happen
        alert("No game over scene defined, loaded last save instead");
        this.loadGame();
      } else {
        this.loadScene(gameOverScene);
      }
    },
    keyDown(e: KeyboardEvent) {
      if (e.code === "ControlLeft") {
        this.skipSentence();
      }

      // Restart fight if game over
      if (e.code === "KeyR") {
        if (this.bools.aboutToShowGameover) return;

        if (this.showGameover) {
          this.gameoverResolve();
          this.bools.resetPressed = true;
          this.bools.reloadRequest = !this.bools.reloadRequest;
        } else if (this.bools.battleActive) {
          this.bools.reloadRequest = !this.bools.reloadRequest;
        }
      }

      if (e.code === "F4") {
        this.bools.showDebug = this.bools.showDebugBattle =
          !this.bools.showDebug;
      }
    },
    isBloodNote() {
      return BLOOD_NOTES_CHARACTERS.includes(this.strings.currentCharacter);
    },
    onCinematicCaptionsToggle() {
      const videoTag = this.getHtmlCinematicTag() as HTMLVideoElement;

      this.bools.showCinematicCaptions = !this.bools.showCinematicCaptions;

      // Save setting
      localStorage.setItem(
        "settings_showCinematicSubtitles",
        this.bools.showCinematicCaptions ? "1" : "0"
      );

      videoTag.textTracks[0].mode = this.bools.showCinematicCaptions
        ? "showing"
        : "hidden";
    },
    onDyslexicToggle() {
      this.bools.dyslexic = !this.bools.dyslexic;

      // Save setting
      localStorage.setItem(
        "settings_dyslexic",
        this.bools.dyslexic ? "1" : "0"
      );
    },
    onScreenShakeToggle() {
      this.bools.screenShake = !this.bools.screenShake;

      // Save setting
      localStorage.setItem(
        "settings_screenShake",
        this.bools.screenShake ? "1" : "0"
      );
    },
    onInvincibleToggle() {
      this.bools.invincible = !this.bools.invincible;

      // Save setting
      localStorage.setItem(
        "settings_invincible",
        this.bools.invincible ? "1" : "0"
      );
    },
    onVisualHelpToggle() {
      Bullet.visualHelp = !Bullet.visualHelp;

      // Save setting
      localStorage.setItem(
        "settings_visualHelp",
        Bullet.visualHelp ? "1" : "0"
      );
    },
    async smoothTransitionMusicBanner(newMusic: string) {
      // If a transition is needed
      if (this.bools.showMusic && this.strings.currentMusic !== newMusic) {
        this.bools.showMusic = false;
        await new Promise<void>((resolve) => {
          this.onMusicHideEnd = resolve;
        });
      }
      this.strings.currentMusic = newMusic;
      this.bools.showMusic = true;
    },
    async onBackgroundMusicPause() {
      // When background music finished and is on battle mode, stop the battle
      if (
        this.bools.showBattle &&
        this.audioSources.music_background.getCurrentTime() ===
          this.audioSources.music_background.getDuration()
      ) {
        // Don't stop fight if in debug mode
        if (!this.bools.showDebugBattle) {
          this.bools.battleActive = false;

          if (!NO_NOTES_CHARACTERS.includes(this.strings.currentCharacter)) {
            // Wait player acknowledgement of note
            if (this.isBloodNote()) {
              this.audioSources.effects_gotBloodNote.play();
            } else {
              this.audioSources.effects_gotGoldenNote.play();
            }
            this.showGotNote = true;
            await new Promise<void>((resolve) => {
              this.gotNoteResolve = resolve;
            });
          }

          // Wait for player acknowledgement of 3 golden notes fetched
          if (!this.isBloodNote() && this.goldenNotes >= 2) {
            this.audioSources.effects_got3Notes.play();
            this.showGot3Notes = true;
            await new Promise<void>((resolve) => {
              this.got3NotesResolve = resolve;
            });
          }

          this.bools.showChoices = true;
          this.bools.showText = true;

          // Wait that battle panel finished hiding
          this.bools.showBattle = false;
          await new Promise<void>((resolve) => {
            this.onBattleHideEnd = resolve;
          });

          if (!NO_NOTES_CHARACTERS.includes(this.strings.currentCharacter)) {
            // Increment correct counter
            if (this.isBloodNote()) {
              this.bloodBosses.push(this.strings.currentCharacter);
              this.updateBloodNotes();
            } else {
              ++this.goldenNotes;
            }
          }

          this.loadScene(`${this.strings.currentBattle}_after_fight`);

          this.saveGame();
        }
      }
    },
    onCinematicPause(e: Event) {
      if (!this.bools.showCinematic) return;

      const videoTag = e.target as HTMLVideoElement;

      // Prevent pause during the cinematic
      if (videoTag.currentTime !== videoTag.duration) {
        videoTag.play();
      } else {
        // When a cinematic ends, play the first choice on the list
        // (must be the skip cinematic button)
        this.onClickChoice(this.choices[0], false);
      }
    },
    getHtmlCinematicTag() {
      if (this.cinematicVideoTag === null) {
        this.cinematicVideoTag = document.getElementById(
          "cinematic"
        ) as HTMLVideoElement | null;
        if (this.cinematicVideoTag === null)
          throw new Error("Could not get cinematic video tag");
      }
      return this.cinematicVideoTag;
    },
    // Sound sliders

    onWheelMasterVolume(event: WheelEvent) {
      event.preventDefault();
      this.setMasterVolume(
        this.masterVolume + -event.deltaY / (1 / SETTINGS_RANGE_FORCE) / 100
      );
    },
    onWheelMusicVolume(event: WheelEvent) {
      event.preventDefault();
      this.setMusicVolume(
        this.musicVolume + -event.deltaY / (1 / SETTINGS_RANGE_FORCE) / 100
      );
    },
    onWheelSoundEffectsVolume(event: WheelEvent) {
      event.preventDefault();
      this.setSoundEffectsVolume(
        this.soundEffectsVolume +
          -event.deltaY / (1 / SETTINGS_RANGE_FORCE) / 100
      );
    },
    onSoundEffectsVolumeChange(event: any) {
      this.setSoundEffectsVolume(event.target.valueAsNumber / 100);
    },
    onMusicVolumeChange(event: any) {
      this.setMusicVolume(event.target.valueAsNumber / 100);
    },
    onMasterVolumeChange(event: any) {
      this.setMasterVolume(event.target.valueAsNumber / 100);
    },
    setMasterVolume(newVolume: number) {
      this.masterVolume = newVolume < 0 ? 0 : newVolume > 1 ? 1 : newVolume;

      this.setMusicVolume(this.musicVolume);
      this.setSoundEffectsVolume(this.soundEffectsVolume);

      localStorage.setItem(
        "settings_masterVolume",
        this.masterVolume.toString()
      );
    },
    setMusicVolume(newVolume: number) {
      // Clamp between 0 and 1
      this.musicVolume = newVolume < 0 ? 0 : newVolume > 1 ? 1 : newVolume;

      for (let key in this.audioSources) {
        if (key.startsWith("music_"))
          this.audioSources[key].setVolume(
            this.musicVolume * this.masterVolume
          );
      }

      localStorage.setItem("settings_musicVolume", this.musicVolume.toString());
    },
    setSoundEffectsVolume(newVolume: number) {
      // Clamp between 0 and 1
      this.soundEffectsVolume =
        newVolume < 0 ? 0 : newVolume > 1 ? 1 : newVolume;

      for (let key in this.audioSources) {
        if (key.startsWith("effects_"))
          this.audioSources[key].setVolume(
            this.soundEffectsVolume * this.masterVolume
          );
      }

      localStorage.setItem(
        "settings_soundEffectsVolume",
        this.soundEffectsVolume.toString()
      );
    },
    async shakeImage() {
      if (!this.bools.screenShake) return;
      const elem = document.getElementById("image-panel") as HTMLImageElement;
      elem.classList.remove("image-container-shake");
      // Arnaque moldave to make it work
      await new Promise((r) => setTimeout(r, 50));
      elem.classList.add("image-container-shake");

      this.audioSources.effects_bass.play();
    },
    skipSentence() {
      // Assert that there is something to skip
      if (this.sentences.length === 0) return;
      if (!this.bools.sentenceExecuting) return;
      if (!this.bools.textSkippable) return;

      this.sentences[this.sentences.length - 1].noAnim = true;
      this.sentenceSemaphore.release();
      this.bools.sentenceSkipped = true;
      this.skipPauseResolve();
    },
    areEqual(array1: any[], array2: any[]) {
      if (array1.length === array2.length) {
        return array1.every((element, index) => {
          if (element === array2[index]) {
            return true;
          }

          return false;
        });
      }

      return false;
    },
    saveGame() {
      // Save conditions
      localStorage.setItem("save_conditions", this.conditions.join(";"));

      // Save blood bosses
      localStorage.setItem("save_bloodBosses", this.bloodBosses.join(";"));

      // Save notes
      localStorage.setItem("save_goldenNotes", this.goldenNotes.toString());

      // Save current scene
      localStorage.setItem("save_currentScene", this.strings.currentScene);
    },
    markChapterFinished(chapterFinished: number) {
      // Add finished hcapter to the list and save it
      let finishedChapters = localStorage.getItem("save_finishedChapters");
      let finishedChaptersArr: string[] = [];
      if (finishedChapters !== null) {
        finishedChaptersArr = finishedChapters.split(";");
      }
      finishedChaptersArr.push(chapterFinished.toString());
      localStorage.setItem(
        "save_finishedChapters",
        finishedChaptersArr.join(";")
      );
    },
    startChapter(chapterNum: number) {
      if (this.strings.currentScene === null || this.bools.sentenceExecuting) {
        return;
      }

      let isOk = confirm(
        `Are you sure you want to start at chapter ${chapterNum}?\nYour golden notes will be removed but your blood notes will be kept.`
      );
      if (!isOk) return;

      if (this.strings.currentScene === null || this.bools.sentenceExecuting) {
        return;
      }

      switch (chapterNum) {
        case 1:
          this.loadScene("splashscreen");
          break;
        case 2:
          this.loadScene("chapter2_splashscreen");
          break;
        case 3:
          // Coming soon
          break;
        default:
          alert("Cannot start chapter: Invalid chapter number");
          return;
      }

      this.goldenNotes = 0;
      this.conditions = [];
      this.saveGame();
      this.bools.showSettings = false;
    },
    resetSave() {
      let isOk = confirm(
        "Are you sure you want to reset your progress?\nAll your notes will be removed and all chapters will be marked as unfinished."
      );
      if (!isOk) return;

      // Reset all localstorage (except for settings)
      localStorage.removeItem("save_conditions");
      localStorage.removeItem("save_goldenNotes");
      localStorage.removeItem("save_currentScene");
      localStorage.removeItem("save_finishedChapters");
      localStorage.removeItem("save_bloodBosses");

      alert("All progress removed.");
      location.reload();
    },
    loadSettings() {
      // Sound effects volume
      const localSoundEffectsVolume = localStorage.getItem(
        "settings_soundEffectsVolume"
      );
      if (localSoundEffectsVolume !== null)
        this.setSoundEffectsVolume(parseFloat(localSoundEffectsVolume));

      // Music volume
      const localMusicVolume = localStorage.getItem("settings_musicVolume");
      if (localMusicVolume !== null)
        this.setMusicVolume(parseFloat(localMusicVolume));

      // Master volume
      const localMasterVolume = localStorage.getItem("settings_masterVolume");
      if (localMasterVolume !== null)
        this.setMasterVolume(parseFloat(localMasterVolume));

      // Show cinematic subtitles
      let showCinematicSubtitles = localStorage.getItem(
        "settings_showCinematicSubtitles"
      );
      if (showCinematicSubtitles === null) showCinematicSubtitles = "0";
      if (showCinematicSubtitles === "1") {
        this.onCinematicCaptionsToggle();
      }

      // Dyslexic font
      let dyslexic = localStorage.getItem("settings_dyslexic");
      if (dyslexic === null) dyslexic = "0";
      if (dyslexic === "1") {
        this.onDyslexicToggle();
      }

      // Screenshake
      let screenShake = localStorage.getItem("settings_screenShake");
      if (screenShake === null) screenShake = "0";
      if (screenShake === "1") {
        this.onScreenShakeToggle();
      }

      // Invincible
      let invincible = localStorage.getItem("settings_invincible");
      if (invincible === null) invincible = "0";
      if (invincible === "1") {
        this.onInvincibleToggle();
      }

      // Visual help
      let visualHelp = localStorage.getItem("settings_visualHelp");
      if (visualHelp === null) visualHelp = "0";
      if (visualHelp === "1") {
        this.onVisualHelpToggle();
      }
    },
    loadGame() {
      // Load conditions
      const savedConditions = localStorage.getItem("save_conditions");
      if (savedConditions !== null) {
        this.conditions = savedConditions.split(";");
      }

      // Load blood bosses
      const bloodBossesSaved = localStorage.getItem("save_bloodBosses");
      if (bloodBossesSaved !== null) {
        this.bloodBosses = bloodBossesSaved.split(";");
      }
      this.updateBloodNotes();

      // Load notes
      const goldenNotesSaved = localStorage.getItem("save_goldenNotes");
      if (goldenNotesSaved !== null) {
        this.goldenNotes = parseInt(goldenNotesSaved);
      }

      // Load current scene
      let currentScene = localStorage.getItem("save_currentScene");
      if (currentScene === null) {
        // First scene
        currentScene = "splashscreen";
        localStorage.setItem("save_currentScene", currentScene);
      }
      this.loadScene(currentScene);
    },
    setCondition(...conditionsToAdd: string[]) {
      for (let i = 0; i < conditionsToAdd.length; ++i) {
        if (!this.conditions.includes(conditionsToAdd[i]))
          this.conditions.push(conditionsToAdd[i]);
      }
    },
    isChoiceLegal(choice: IChoice) {
      const sceneName = choice.next.slice(2);

      if (choice.next.startsWith("t:")) {
        // If the ephemeral text has already been seen, add a little label to the choice button
        // Use sceneName and length of the text as a unique identifier
        choice.visited = this.conditions.includes(
          `visited_ephemeral_${this.strings.currentScene}_${sceneName.length}`
        );
        return true;
      }
      if (choice.next.startsWith("g:")) {
        return this.goldenNotes >= 3;
      }
      if (!choice.next.startsWith("s:")) return true;

      if (!(sceneName in this.scenes)) {
        alert(`Choice going to nonexistent scene "${sceneName}"`);
        console.warn(`Choice going to nonexistent scene "${sceneName}"`);
        return false;
      }
      const scene = this.scenes[sceneName];

      // If a with condition is not present in the condition table, invalidate choice
      if (scene.with !== undefined) {
        const withConditionIntersection = this.conditions.filter((value) =>
          (scene.with as string[]).includes(value)
        );
        if (!this.areEqual(scene.with, withConditionIntersection)) return false;
      }

      // If a without condition is present in the conditions table, invalidate choice
      if (scene.without !== undefined) {
        const withoutConditionIntersection = this.conditions.filter((value) =>
          (scene.without as string[]).includes(value)
        );
        if (withoutConditionIntersection.length > 0) return false;
      }

      // If the scene has already be visited, add a little label to the choice button
      choice.visited = this.conditions.includes(`visited_${sceneName}`);

      return true;
    },
    animatedSentenceEnd(event: AnimationEvent) {
      if (event.animationName === "typewriter") {
        // When a typewriter animation end, release the mutex blocking the function
        // that is executing the sentence
        if (!this.bools.skipFlicker) this.sentenceSemaphore.release();
      }
    },
    async setFxSentence(newFxSentence: string) {
      const newUrl = `${window.location.origin}/soundfx/sentence/${newFxSentence}.ogg`;
      if (newUrl !== this.audioSources.effects_sentence.url) {
        this.audioSources.effects_sentence.url = `soundfx/sentence/${newFxSentence}.ogg`;

        // Wait for audio to load
        this.bools.isLoading = true;
        await new Promise<void>((resolve) => {
          this.audioSources.effects_sentence.onLoad = resolve;
        });
        this.bools.isLoading = false;
      }
    },
    async executeText(textToParse: string, overrideFont = "") {
      this.sentenceSemaphore.setValue(0);

      this.bools.sentenceSkipped = false;
      this.bools.sentenceExecuting = true;
      const newLineSentence: ISentence = {
        text: "",
        timePerLetter: 0,
        newLine: true,
        customClass: "",
        customColor: "",
        noAnim: true,
        overrideFont,
      };
      const timePerLetterStep = 0.005;
      const pauseTimeMs = 400;
      let displayText = "";
      let timePerLetter = 0.03;
      let isBold = false;
      let isUnderline = false;
      let isWiggly = false;
      let isWavy = false;
      let isItalic = false;
      let doublePauseTime = false;
      let customColor = "black";
      for (let i = 0; i < textToParse.length + 1; ++i) {
        if (",.!?".includes(textToParse[i])) {
          displayText += textToParse[i];
        }
        if (
          ("<>|;/=_~#%$&,.!?".includes(textToParse[i]) ||
            i === textToParse.length) &&
          displayText.length !== 0
        ) {
          // Add sentence
          let customClass = "";
          if (isBold) customClass += " bold";
          if (isUnderline) customClass += " underline";
          if (isWiggly) customClass += " wiggly";
          if (isWavy) customClass += " wave";
          if (isItalic) customClass += " italic";
          this.sentences.push({
            text: displayText,
            timePerLetter,
            newLine: false,
            customClass,
            customColor,
            noAnim: this.bools.sentenceSkipped,
            overrideFont,
          });

          // dirty fix
          if (overrideFont === "normal_text") {
            await this.setFxSentence(DEFAULT_SENTENCE_FX);
          } else if (
            this.scenes[this.strings.currentScene].fxSentence !== undefined
          ) {
            await this.setFxSentence(
              this.scenes[this.strings.currentScene].fxSentence as string
            );
          }
          this.audioSources.effects_sentence.play();
          if (overrideFont === "") this.bools.characterWiggle = true;
          this.bools.textSkippable = true;

          // Wait for the text to have finished displaying
          if (!this.bools.noDelayDebug && !this.bools.sentenceSkipped) {
            await this.sentenceSemaphore.acquire();
          }

          this.bools.textSkippable = false;
          if (overrideFont === "") this.bools.characterWiggle = false;
          this.audioSources.effects_sentence.pause();

          // Clear text because it has been added
          displayText = "";
        }
        doublePauseTime = false;

        // Handle control char
        switch (textToParse[i]) {
          case "<": // Increase time between letters (slow down)
            timePerLetter += timePerLetterStep;
            continue;
          case ">": // Reduce time between letters (speed up)
            timePerLetter -= timePerLetterStep;
            continue;
          case ".":
          case "?":
          case "!":
            doublePauseTime = true;
          case ",":
            if (i + 1 >= textToParse.length || textToParse[i + 1] !== " ") {
              continue;
            }
          case "|": // Pause
            if (this.bools.noDelayDebug || this.bools.sentenceSkipped) continue;
            this.bools.textSkippable = true;
            await new Promise<void>((resolve) => {
              this.skipPauseResolve = resolve;
              setTimeout(
                resolve,
                doublePauseTime ? 2 * pauseTimeMs : pauseTimeMs
              );
            });
            this.bools.textSkippable = false;
            continue;
          case ";": // Newline
            this.sentences.push(newLineSentence);
            continue;
          case "/": // Delete previous sentence
            this.sentences.pop();
            continue;
          case "=": // Toggle bold mode
            isBold = !isBold;
            continue;
          case "_": // Toggle underline mode
            isUnderline = !isUnderline;
            continue;
          case "%": // Toggle wiggle mode
            isWiggly = !isWiggly;
            continue;
          case "~": // Toggle wave mode
            isWavy = !isWavy;
            continue;
          case "#": // Toggle italic mode
            isItalic = !isItalic;
            continue;
          case "&": // Shake the image
            this.shakeImage();
            continue;
          case "$": // Enter color selection mode
            switch (textToParse[i + 1]) {
              case "1":
                customColor = "black";
                break;
              case "2":
                customColor = "#b5a642";
                break; // brass color
              case "3":
                customColor = "purple";
                break; // power color (and music color)
              case "4":
                customColor = "#3367e3";
                break; // maestro color
              case "5":
                customColor = "#d70b0b";
                break; // blood color
              case "6":
                customColor = "#ad5311";
                break; // string color
              case "7":
                customColor = "#d4af37";
                break; // gold color
              case "8":
                customColor = "#2F6DC9";
                break; // woodwinds kingdom
              case "9":
                customColor = "#14A356";
                break; // anti-music color
              case "ü":
                customColor = "#e15724";
                break; // cone orange
              case "ö":
                customColor = "#e19a81";
                break; // cone white
              case "0":
                customColor = "black";
                break;
              default:
                console.error("Color selection is incorrect");
            }
            i++;
            continue;
        }

        displayText += textToParse[i];
      }
    },
    async loadScene(sceneName: string) {
      if (!(sceneName in this.scenes)) {
        alert(`Trying to load scene "${sceneName}", which does not exist`);
        return;
      }

      this.audioSources.music_singerAveMaria.pause();
      this.overrideDisplaySinger = false;

      const scene = this.scenes[sceneName];
      this.bools.choicesInvisible = true;
      this.bools.sentenceExecuting = true;
      this.strings.currentScene = sceneName;

      this.bools.showText = true;
      this.bools.showChoices = true;

      // Mark this scene as visited
      this.setCondition(`visited_${sceneName}`);

      // Checkpoint ! Save current states
      if (scene.checkpoint !== undefined) {
        this.saveGame();
      }

      // Remove all sentences
      this.sentences = [];

      // Load sentence sound effect, if there is none, set default
      if (scene.fxSentence !== undefined) {
        await this.setFxSentence(scene.fxSentence);
      } else {
        await this.setFxSentence(DEFAULT_SENTENCE_FX);
      }

      // Load new character, if there is none, set none
      if (scene.character !== undefined) {
        const newCharacter = this.scenes[sceneName].character as string;

        // On character change, arnaque moldave
        if (newCharacter !== this.strings.currentCharacter) {
          this.strings.currentCharacter = "gate";

          // Arnaque moldave so it works
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        this.strings.currentCharacter = newCharacter;
        this.bools.showCharacter = true;
        this.strings.currentFont = newCharacter;
      } else {
        this.bools.showCharacter = false;
        this.strings.currentFont = "normal_text";
      }

      // Load new image if there is one
      if (scene.image !== undefined) {
        // Stop cinematic if there was one
        if (this.bools.showCinematic === true) {
          const videoTag = this.getHtmlCinematicTag();
          if (!isNaN(videoTag.duration)) {
            videoTag.currentTime = videoTag.duration;
          }
          videoTag.pause();

          // Wait for cinematic hide animation to finish
          this.bools.showCinematic = false;
          await new Promise<void>((resolve) => {
            this.onCinematicHideEnd = resolve;
          });

          this.strings.currentCinematic = "empty";
        }

        // If the image is different from the current one, do a smooth transition
        if (this.strings.currentImage !== scene.image) {
          if (this.bools.showImage) {
            // Hide image
            this.bools.showImage = false;

            // Wait for the image to finish hiding
            await new Promise<void>((resolve) => {
              this.onImageHideEnd = resolve;
            });
          }

          // Change image
          this.strings.currentImage = scene.image;

          // Wait for the image to finish loading
          this.bools.isLoading = true;
          await new Promise<void>((resolve) => {
            this.onImageLoad = resolve;
          });
          this.bools.isLoading = false;

          // Wait a bit because ça bug
          await new Promise<void>((resolve) => {
            setTimeout(resolve, 500);
          });

          // Show the image
          this.bools.showImage = true;

          // Wait for the image to finish showing
          await new Promise<void>((resolve) => {
            this.onImageShowEnd = resolve;
          });
        }
      }

      // Load music if there is one
      if (scene.music !== undefined) {
        const musicFile = `music/${scene.music}.ogg`;

        // If it is a different audio file
        if (
          !this.audioSources.music_background.url.includes(encodeURI(musicFile))
        ) {
          this.audioSources.music_background.pause();
          this.audioSources.music_background.loop = true;

          // Wait for the audio file to load
          this.audioSources.music_background.url = musicFile;
          this.bools.isLoading = true;
          await new Promise<void>((resolve) => {
            this.audioSources.music_background.onLoad = resolve;
          });
          this.bools.isLoading = false;
        }

        // Make sure audio is playing
        this.audioSources.music_background.play();

        // Update music banner
        // If music name is set to "MUTE", it means we stop the music
        if (scene.music === "MUTE") {
          this.bools.showMusic = false;
        } else if (scene.music.startsWith("the_singer")) {
          await this.smoothTransitionMusicBanner("???");

          // Good idea, but the whispers are annoying
          //this.audioSources.music_singerAveMaria.play();

          this.overrideDisplaySinger = true;
        } else if (this.strings.currentMusic !== scene.music) {
          await this.smoothTransitionMusicBanner(scene.music);
        }
      }

      // Overwrite music name (but do not play any music)
      if (scene.overwriteMusic !== undefined) {
        await this.smoothTransitionMusicBanner(scene.overwriteMusic);
      }

      // Load cinematic if there is one
      const videoTag = this.getHtmlCinematicTag();
      if (scene.cinematic !== undefined) {
        this.bools.showImage = false;
        this.bools.showText = false;
        this.strings.currentCinematic = scene.cinematic;

        // Wait for cinematic video to load
        this.bools.isLoading = true;
        await new Promise<void>((resolve) => {
          this.onCinematicLoad = resolve;
        });
        this.bools.isLoading = false;

        // Wait for cinematic show animation to finish
        this.bools.showCinematic = true;
        await new Promise<void>((resolve) => {
          this.onCinematicShowEnd = resolve;
        });

        // Set current image to black
        this.strings.currentImage = "_";

        // Stop background music
        this.audioSources.music_background.pause();

        // Play cinematic
        videoTag.play();
      }

      // Load only legal choices
      this.choices = this.scenes[sceneName].choices.filter(this.isChoiceLegal);

      // Execute text
      await this.executeText(this.scenes[sceneName].text);
      this.bools.sentenceExecuting = false;
      this.bools.sentenceSkipped = false;

      if (scene.battle !== undefined) {
        this.strings.currentBattle = this.scenes[sceneName].battle as string;

        // Remove text container
        this.sentences = [];
        this.bools.showText = false;

        // Wait for the battle show animation to finish
        this.bools.showChoices = false;
        this.bools.showBattle = true;
        await new Promise<void>((resolve) => {
          this.onBattleShowEnd = resolve;
        });

        // Play battle music
        const musicName = this.battles[this.strings.currentBattle]
          .music as string;
        const musicFile = `music/${musicName}.ogg`;
        this.audioSources.music_background.loop = false;
        this.smoothTransitionMusicBanner(musicName);

        // Wait for the audio file to load
        this.audioSources.music_background.url = musicFile;
        this.bools.isLoading = true;
        await new Promise<void>((resolve) => {
          this.audioSources.music_background.onLoad = resolve;
        });
        this.bools.isLoading = false;

        // If the battle has been halted, go to correct time.
        this.battleTime = 0;
        if (scene.battleTime !== undefined) {
          this.battleTime = scene.battleTime;
        }

        // Activate battle
        this.bools.battleActive = true;
        this.choices = [];
      }

      this.bools.choicesInvisible = false;
    },
    onClickSettings() {
      this.bools.showSettings = true;
    },
    async executeEphemeralText(textToParse: string, overrideFont = "") {
      let sentenceNumBefore = this.sentences.length;
      await this.executeText(textToParse, overrideFont);

      // Once we acquire this mutex, we wait for the user to release it
      this.bools.textSkippable = true;
      this.bools.skipFlicker = true;
      this.bools.sentenceSkipped = false;
      this.sentenceSemaphore.setValue(0);
      await this.sentenceSemaphore.acquire();
      this.bools.sentenceExecuting = false;
      this.bools.skipFlicker = false;
      this.bools.textSkippable = false;

      // Remove text
      this.sentences.splice(
        sentenceNumBefore,
        this.sentences.length - sentenceNumBefore
      );
    },
    async onClickChoice(choice: IChoice, withSound = true) {
      if (withSound) this.audioSources.effects_click.play();

      if (this.bools.sentenceExecuting) {
        this.skipSentence();
        return;
      }

      // Update conditions that this choices sets
      if (choice.setConditions !== undefined) {
        this.setCondition(...choice.setConditions);
      }

      const action = choice.next[0];
      const actionValue = choice.next.slice(2);

      const bosses = [
        "tuba_knight",
        "tuba_gunman",
        "tuba_infantry",
        "heavy_tuba_gunner",
        "heavy_tuba_gunner_pissed_of",
        "legionary_violin",
        "tuba_lunatic",
        "tuba_wizard",
        "tuba_high_priest",
        "cone_man",
        "tuba_defender",
        "tuba_knight_come_back",
        "tuba_jester",
        "tuba_archmage",
        "corrupted_violin_fencer",
        "king_tuba_2",
        "tuba_angel",
      ];

      let singerText = ";";

      switch (action) {
        case "t": // Display ephemeral text
          this.setCondition(
            `visited_ephemeral_${this.strings.currentScene}_${actionValue.length}`
          );
          choice.visited = true;
          await this.executeEphemeralText(actionValue);
          break;
        case "s": // Change scene
          this.loadScene(actionValue);
          break;
        case "n": // NEEXT
          this.skipSentence();
          break;
        case "e": // End chapter
          this.gameend(parseInt(actionValue));
          break;
        case "g": // Present golden notes
          this.bools.sentenceExecuting = true;
          this.audioSources.effects_chord1.play();
          this.goldenNotes -= 3;
          await new Promise((resolve) => setTimeout(resolve, 2000));
          this.audioSources.effects_gateOpen.play();
          await new Promise((resolve) => setTimeout(resolve, 1000));

          this.loadScene(actionValue);
          break;
        case "x": // Diapason invalidates choice
          this.audioSources.effects_bassLong.play();
          this.shakeImage();

          // Remove choice with the same text from the list
          for (let i = 0; i < this.choices.length; ++i) {
            if (this.choices[i].text === choice.text) {
              this.choices.splice(i, 1);
            }
          }
          await this.executeEphemeralText(
            ";An horrible pain coming from the $3~Diapason~$1 runs through your whole body. The words just won't come out of your lips.",
            "normal_text"
          );

          break;
        case "y": // Display stats of game overs (from chapter 1 to 2)
          for (const boss of bosses) {
            if (this.conditions.includes(`visited_${boss}_fight`)) {
              singerText += `${await this.transformString(
                boss
              )}: ${await this.countInfo(`gameover_${boss}`)}, `;
            }
          }
          singerText = singerText.slice(0, -2); // remove ", " en trop

          await this.executeEphemeralText(singerText, "singer");

          break;
      }
    },
    setCookie(cname: string, cvalue: string) {
      // Expire dans longtemps sa mère
      let expires = "expires=" + new Date(2147483647 * 1000).toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    setAllNotif(notifVal: NotifType) {
      for (const notifName of NOTIF_LIST) {
        this.notifications.set(notifName, notifVal);
        localStorage.setItem(`notif_${notifName}`, notifVal);
      }
    },
    setAllBossesConditions() {
      const bosses = [
        "tuba_knight",
        "tuba_gunman",
        "tuba_infantry",
        "heavy_tuba_gunner",
        "heavy_tuba_gunner_pissed_of",
        "legionary_violin",
        "tuba_lunatic",
        "tuba_wizard",
        "tuba_high_priest",
        "cone_man",
        "tuba_defender",
        "tuba_knight_come_back",
        "tuba_jester",
        "tuba_archmage",
        "corrupted_violin_fencer",
        "king_tuba_2",
        "tuba_angel",
      ];

      for (const boss of bosses) {
        this.setCondition(`visited_${boss}_fight`);
      }
    },
    clearNotif(notifName: string) {
      this.notifications.set(notifName, "0");
      localStorage.setItem(`notif_${notifName}`, "0");
    },
    isThereANotif() {
      for (const notifVal of this.notifications.values()) {
        if (notifVal == "1") return true;
      }
      return false;
    },
    getCookie(cname: string) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    countHit(countName: string) {
      fetch(`https://counter.saraivam.ch/hit.php?c=${countName}`);
    },
    async countInfo(countName: string) {
      const res = await fetch(
        `https://counter.saraivam.ch/info.php?c=${countName}`
      );
      const json = await res.json();
      return parseInt(json.value);
    },
    async transformString(input: string) {
      // Replace underscores with spaces
      let result = input.replace(/_/g, " ");

      // Split the string into an array of words
      let words = result.split(" ");

      // Capitalize the first letter of each word
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }

      // Join the array back into a single string
      result = words.join(" ");

      return result;
    },
  },
  watch: {
    // TODO make something generic for ephemeral prompt instead of this mess
    showGotNote(current, old) {
      if (current === false) this.gotNoteResolve();
    },

    showGot3Notes(current, old) {
      if (current === false) this.got3NotesResolve();
    },

    showGameEnd(current, old) {
      if (current === false) this.gameEndResolve();
    },

    showGameover(current, old) {
      if (current === false) this.gameoverResolve();
    },

    showEpilepsyWarning(current, old) {
      if (current === false) this.epilepsyWarningResolve();
    },
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === "production") {
      this.bools.showDebug = false;
      this.bools.showDebugBattle = false;
    }

    this.countInfo("tuba_visits").then((val) => {
      this.numUniqueVisits = val;
    });

    // Load notifications
    for (const notifName of NOTIF_LIST) {
      let notifVal = localStorage.getItem(`notif_${notifName}`);
      if (notifVal !== "0" && notifVal !== "1") {
        notifVal = "1"; // By default, notifications are enabled
        localStorage.setItem(`notif_${notifName}`, notifVal);
      }
      this.notifications.set(notifName, notifVal as NotifType);
    }

    // Unique visit counter
    const uniqueVisitId = localStorage.getItem("uniqueVisitId");
    if (uniqueVisitId === null) {
      // Set unique visite id
      localStorage.setItem("uniqueVisitId", Math.random().toString());

      this.countHit("tuba_visits");
    }

    // Check correct window size
    const checkResize = () => {
      this.bools.screenTooSmall = window.matchMedia(
        "(max-width: 1349px)"
      ).matches;
    };
    window.onresize = checkResize;
    checkResize();

    this.audioSources.music_background.onPause = this.onBackgroundMusicPause;

    this.loadSettings();

    // Show epilepsy warning
    if (!this.bools.showDebug) {
      // Wait for user to close warning prompt
      this.showEpilepsyWarning = true;
      await new Promise<void>((resolve) => {
        this.epilepsyWarningResolve = resolve;
      });
    }

    this.loadGame();

    document.addEventListener("keydown", this.keyDown);
  },
  unmounted() {
    // TODO pause all audio on unmount
    this.audioSources.effects_sentence.pause();
    document.removeEventListener("keydown", this.keyDown);
  },
});
</script>

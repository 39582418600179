export class AudioSource {
  static audioSourceNum = 0;
  id: number;
  sampleNum: number;
  sampleIndex: number;
  audioElements: HTMLAudioElement[];
  isPlaying: boolean;

  constructor(url: string, loop = false, sampleNum = 1) {
    if (sampleNum < 1) {
      throw Error("Number of samples must be > 0 for AudioSource");
    }
    this.id = AudioSource.audioSourceNum++;
    this.sampleNum = sampleNum;
    this.sampleIndex = 0;
    this.isPlaying = false;
    this.audioElements = [];
    for (let i = 0; i < sampleNum; ++i) {
      this.audioElements.push(new Audio(url));
      this.audioElements[i].loop = loop;
      this.audioElements[i].oncanplaythrough = () => {
        this.onLoad();
      };
      this.audioElements[i].onpause = () => {
        this.onPause();
      };
    }
  }

  set url(url) {
    for (let i = 0; i < this.sampleNum; ++i) {
      this.audioElements[i].src = url;
    }
  }

  get url() {
    return this.audioElements[0].src;
  }

  set loop(loop) {
    for (let i = 0; i < this.sampleNum; ++i) {
      this.audioElements[i].loop = loop;
    }
  }

  get loop() {
    return this.audioElements[0].loop;
  }

  onLoad() {}
  onPause() {}

  getAudioElement(doesIncrementIndex = true) {
    if (doesIncrementIndex) {
      // Alternate between multiple audio elements
      this.sampleIndex = (this.sampleIndex + 1) % this.sampleNum;
    }
    return this.audioElements[this.sampleIndex];
  }

  getAudioName() {
    return this.url.substring(
      this.url.lastIndexOf("/") + 1,
      this.url.lastIndexOf(".")
    );
  }

  play() {
    const audioElement = this.getAudioElement();

    audioElement.play().catch((e: Error) => {
      if (e instanceof DOMException && e.name === "NotAllowedError") {
        // Ignore this so the console looks nicer
        // This error occurs when we try to play a sound and that the user hasn't click once on the navigator yet
        return;
      }
      throw e;
    });
    this.isPlaying = true;

    return this.sampleIndex;
  }

  pauseId(id: number) {
    this.audioElements[id].pause();
  }

  pause() {
    for (let i = 0; i < this.sampleNum; ++i) {
      this.audioElements[i].pause();
    }
    this.isPlaying = false;
  }

  getCurrentTime() {
    const audioElement = this.getAudioElement(false);
    return audioElement.currentTime;
  }

  setCurrentTime(newTime: number) {
    const audioElement = this.getAudioElement(false);
    audioElement.currentTime = newTime;
  }

  getDuration() {
    const audioElement = this.getAudioElement(false);
    return audioElement.duration;
  }

  setVolume(newVolume: number) {
    for (let i = 0; i < this.sampleNum; ++i) {
      this.audioElements[i].volume = newVolume;
    }
  }
}

import "./assets/globals.scss";
import { vfmPlugin } from "vue-final-modal";
import PageMain from "./components/PageMain.vue";
import PageGraph from "./components/PageGraph.vue";
import App from "./App.vue";
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: PageMain,
    },
    {
      path: "/graph",
      name: "Graph",
      component: PageGraph,
    },
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: PageMain,
    },
  ],
});

const app = createApp(App);
vfmPlugin().install(app);
app.use(router);
app.mount("#app");

export function randomInt(min: number, max: number) {
  // min and max is inclusive
  min = Math.ceil(min);
  max = Math.floor(max + 1);
  return Math.floor(Math.random() * (max - min) + min);
}

export function randomFloat(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export interface IPatternFlags {
  isWhole: boolean | undefined;
  isGrenade: boolean | undefined;
  needLeftParry: boolean | undefined;
  needRightParry: boolean | undefined;
  playerAttractionForce: number | undefined;
  anchor: string | undefined;
}

export interface ICredits {
  [heading: string]: {
    [element: string]: string;
  };
}

export interface IChoice {
  text: string;
  next: string;
  visited?: boolean;
  setConditions?: string[];
}

export interface IScene {
  text: string;
  choices: IChoice[];
  fxSentence?: string;
  cinematic?: string;
  image?: string;
  music?: string;
  overwriteMusic?: string; // overwrites music banner
  with?: string[];
  without?: string[];
  character?: string;
  battle?: string;
  battleTime?: number;
  checkpoint?: number;
  nextGraph?: string; // Only used in graph display
  gameOverScene?: string;
}

export interface IScenes {
  [key: string]: IScene;
}

export interface ISentence {
  text: string;
  timePerLetter: number;
  newLine: boolean;
  customClass: string;
  customColor: string;
  noAnim: boolean;
  overrideFont: string;
}

export interface IBattles {
  [key: string]: {
    bulletColor: string;
    bulletBeatFreq: number; // Hertz or beats per seconds
    bulletBeatPhaseShift: number; // seconds
    patterns: {
      time: number;
      name: string;
      options?: any;
    }[];
    music: string;
    background?: string;
  };
}

const img_bullet_half = document.createElement("img") as HTMLImageElement;
img_bullet_half.src = "img/battle/note_half.png";

const img_bullet_whole = document.createElement("img") as HTMLImageElement;
img_bullet_whole.src = "img/battle/note_whole.png";

const img_bullet_g_key = document.createElement("img") as HTMLImageElement;
img_bullet_g_key.src = "img/battle/note_g_key.png";

const img_bullet_g_key_red = document.createElement("img") as HTMLImageElement;
img_bullet_g_key_red.src = "img/battle/note_g_key_red.png";

const img_aura_left = document.createElement("img") as HTMLImageElement;
img_aura_left.src = "img/battle/aura_l.png";

const img_aura_right = document.createElement("img") as HTMLImageElement;
img_aura_right.src = "img/battle/aura_r.png";

const img_note_l = document.createElement("img") as HTMLImageElement;
img_note_l.src = "img/battle/note_l.png";

const img_note_r = document.createElement("img") as HTMLImageElement;
img_note_r.src = "img/battle/note_r.png";

const MAX_LIFETIME = 100; // max seconds of lifetime (in case we forgot to remove it)
const SPAWN_THRESHOLD = 1; // num of seconds of fade-in
const DEATH_THRESHOLD = 0.15; // num of seconds of fade-out
const GRAVITY_FORCE = 100; // for grenades
const GRENADE_SECONDS_BEFORE_BOOM = 3.65;

export enum BulletType {
  half,
  whole,
  grenade,
}

export class Bullet {
  public static ctx = {} as CanvasRenderingContext2D;
  public static showDebug = false;
  public static diap_range = 0;
  public static diap_angle = 0;
  public static radius = 16;
  public static auraScale = 1;
  public static terrainDimension: { x: number; y: number } = { x: 0, y: 0 };
  public static visualHelp = false;
  public static global = null as any;
  type = 0;
  needSide = 0; // 0 = nothing, 1 = left, 2 = right
  x = 0;
  y = 0;
  vx = 0;
  vy = 0;
  customUpdate = (bullet: Bullet, dt: number) => {};
  customValues = {} as any;
  lifetime = MAX_LIFETIME;
  countdownAudioId = 0;

  public static changeColor(newColor: string) {
    img_bullet_half.src = `img/battle/note_half_${newColor}.png`;

    // Special case for cone man
    if (newColor === "f47b0f") {
      img_bullet_whole.src = `img/battle/note_whole_white.png`;
    } else {
      img_bullet_whole.src = `img/battle/note_whole_${newColor}.png`;
    }

    img_bullet_g_key.src = `img/battle/note_g_key_${newColor}.png`;
  }

  isActive() {
    return !this.isSpawning() && !this.isDying();
  }
  isSpawning() {
    return this.lifetime > MAX_LIFETIME - SPAWN_THRESHOLD;
  }
  isDying() {
    return this.lifetime <= DEATH_THRESHOLD;
  }
  detonate() {
    if (MAX_LIFETIME - this.lifetime <= GRENADE_SECONDS_BEFORE_BOOM) {
      this.lifetime = MAX_LIFETIME - GRENADE_SECONDS_BEFORE_BOOM;
      Bullet.global.audioSources.effects_grenadeCountdown.pauseId(
        this.countdownAudioId
      );
    }
  }
  kill() {
    this.lifetime = DEATH_THRESHOLD;
  }

  update(dt: number) {
    if (this.type === BulletType.grenade) {
      // boom
      if (
        !this.isDying() &&
        MAX_LIFETIME - this.lifetime > GRENADE_SECONDS_BEFORE_BOOM
      ) {
        Bullet.global.audioSources.effects_grenadeExplosion.play();
        Bullet.global.shakeCanvas();
        Bullet.global.addDeathCircle(this.x, this.y);
        this.kill();
        return;
      }

      // Add gravity
      this.vy += GRAVITY_FORCE * dt;

      // Bounce on walls
      if (
        (this.vx < 0 && this.x < Bullet.radius) ||
        (this.vx > 0 && this.x > Bullet.terrainDimension.x - Bullet.radius)
      ) {
        this.vx = -(this.vx >> 1);
      }
      if (
        (this.vy < 0 && this.y < Bullet.radius) ||
        (this.vy > 0 && this.y > Bullet.terrainDimension.y - Bullet.radius)
      ) {
        this.vy = -(this.vy >> 1);
      }
    }

    // Move bullet based on their velocity
    this.x += this.vx * dt;
    this.y += this.vy * dt;

    // The only constant in life is change
    this.lifetime -= dt;

    this.customUpdate(this, dt);
  }
  draw(rect: any) {
    if (Bullet.ctx === null) return;

    // Don't draw if grenade already exploded
    if (
      this.type === BulletType.grenade &&
      MAX_LIFETIME - this.lifetime > GRENADE_SECONDS_BEFORE_BOOM
    ) {
      return;
    }

    const x = this.x + rect.x + scrollX;
    const y = this.y + rect.y + scrollY;

    const scale = Bullet.global.num.bulletDisplayScale;
    const rotation = 0;
    const cx = 22.5;
    const cy = 14.5;

    if (this.isDying()) {
      Bullet.ctx.globalAlpha = this.lifetime / DEATH_THRESHOLD;
    } else if (this.isSpawning()) {
      Bullet.ctx.globalAlpha = (MAX_LIFETIME - this.lifetime) / SPAWN_THRESHOLD;
    } else {
      Bullet.ctx.globalAlpha = 1;
    }

    Bullet.ctx.beginPath();
    Bullet.ctx.setTransform(Bullet.auraScale, 0, 0, Bullet.auraScale, x, y);

    // Draw aura if needed
    if (this.needSide == 1) {
      Bullet.ctx.drawImage(img_aura_left, -cx * 2, -cy * 2);
    } else if (this.needSide == 2) {
      Bullet.ctx.drawImage(img_aura_right, -cx * 2, -cy * 2);
    }

    Bullet.ctx.setTransform(scale, 0, 0, scale, x, y);
    Bullet.ctx.rotate(rotation);

    // Draw body
    switch (this.type) {
      case BulletType.half:
        Bullet.ctx.drawImage(img_bullet_half, -cx, -cy);
        break;
      case BulletType.whole:
        Bullet.ctx.drawImage(img_bullet_whole, -cx, -cy);
        break;
      case BulletType.grenade: {
        const oldAlpha = Bullet.ctx.globalAlpha;
        const alpha =
          (MAX_LIFETIME - this.lifetime) / GRENADE_SECONDS_BEFORE_BOOM;

        Bullet.ctx.globalAlpha = (1 - alpha) * oldAlpha;
        Bullet.ctx.drawImage(img_bullet_g_key, -cx + 2, -cy - 45);
        Bullet.ctx.globalAlpha = alpha * oldAlpha;
        Bullet.ctx.drawImage(img_bullet_g_key_red, -cx + 2, -cy - 45);
        break;
      }
    }

    // Draw acessibility visual help if needed
    if (Bullet.visualHelp) {
      if (this.needSide == 1) {
        Bullet.ctx.drawImage(img_note_l, -cx, -cy);
      } else if (this.needSide == 2) {
        Bullet.ctx.drawImage(img_note_r, -cx, -cy);
      }
    }

    Bullet.ctx.setTransform(1, 0, 0, 1, 0, 0);
    Bullet.ctx.closePath();
    Bullet.ctx.globalAlpha = 1;
  }
}

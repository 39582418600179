<template>
  <div class="container">
    <!-- Width and height must be set in a <style> tag otherwise cosmograph can't see the style-->
    <canvas id="graph-canvas" style="width: 800px; height: 450px"> </canvas>
    <div class="zone-container">
      <div
        v-for="[image, color] in imageColorMap"
        :key="image"
        :style="`color: ${color}`"
      >
        {{ image.replaceAll("_", " ") }}
      </div>
    </div>
    <div class="inspect-container" v-if="selectedNode !== undefined">
      <div
        class="image"
        :style="`border-top-color: ${
          selectedNode.data.image === undefined
            ? 'white'
            : imageColorMap.get(selectedNode.data.image)
        };`"
      >
        <img
          :src="`img/scenes/${
            selectedNode.data.image === undefined
              ? 'LOADING'
              : selectedNode.data.image
          }.jpg`"
        />
        <img
          class="character"
          v-if="selectedNode.data.character !== undefined"
          :src="`img/characters/${selectedNode.data.character}.png`"
        />
      </div>
      <div class="text">{{ selectedNode.data.text }}</div>
      <div
        class="choice"
        @click="onChoiceClick(choice)"
        v-for="choice in selectedNode.data.choices"
        :key="choice.text + choice.next"
        :title="choice.next"
      >
        {{ choice.text }}
      </div>
    </div>
    <div class="stats">
      <ul v-for="val in content" :key="val">
        <li>{{ val }}</li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Graph, GraphConfigInterface } from "@cosmograph/cosmos";
import { IScenes, IScene, IChoice } from "../tools";
import scenesJson from "../scenes.json";
import { colord } from "colord";
import { Keccak } from "sha3";
const hashFunction = new Keccak(512);

type Node = {
  id: string;
  data: IScene;
  x?: number;
  y?: number;
};
type Link = {
  source: string;
  target: string;
};

export default defineComponent({
  name: "PageGraph",
  data() {
    return {
      graph: undefined as Graph<Node, Link> | undefined,
      scenes: scenesJson as IScenes,
      imageColorMap: new Map<string, string>(),
      selectedNode: undefined as Node | undefined,
      content: [] as string[],
    };
  },
  methods: {
    async countInfo(countName: string) {
      const res = await fetch(
        `https://counter.saraivam.ch/info.php?c=${countName}`
      );
      const json = await res.json();
      return parseInt(json.value);
    },
    onChoiceClick(choice: IChoice) {
      if (!choice.next.startsWith("s:")) return;

      const nextScene = choice.next.substring(2);
      this.graph?.unselectNodes();
      this.graph?.selectNodeById(nextScene);
      this.selectedNode = (this.graph?.getSelectedNodes() as Node[])[0];
    },
    generateColorFromHash(hash: number): string {
      if (hash < 0) hash = -hash;
      hash = hash % (255 * 255 * 255);
      const r = hash & 255;
      const g = (hash >> 8) & 255;
      const b = (hash >> 16) & 255;

      return colord(`rgb(${r}, ${g}, ${b})`).toHex();
    },
    hashStringToNumber(txt: string) {
      return hashFunction.update(txt).digest().readInt32LE();
    },
  },
  async mounted() {
    this.content.push(
      `Number of unique visits: ${await this.countInfo("tuba_visits")}`
    );

    const BOSSES = [
      "tuba_knight",
      "tuba_gunman",
      "tuba_infantry",
      "heavy_tuba_gunner",
      "heavy_tuba_gunner_pissed_of",
      "legionary_violin",
    ];

    // Log bosses gameover
    for (let i = 0; i < BOSSES.length; ++i) {
      this.content.push(
        `Number of gameover with ${BOSSES[i]}: ${await this.countInfo(
          `gameover_${BOSSES[i]}`
        )}`
      );
    }

    const config: GraphConfigInterface<Node, Link> = {
      spaceSize: 1024,
      nodeSizeScale: 1,
      randomSeed: 42,
      simulation: {
        repulsion: 3,
        linkDistance: 20,
        repulsionFromMouse: 50,
      },
      linkColor: () => "#aaaaaa",
      nodeColor: (node) => {
        //console.log(node.id, this.selectedNode?.id);
        if (node.id === this.selectedNode?.id) return "#ffffff";
        if (node.data.image === undefined) return "#ffffff";
        if (!this.imageColorMap.has(node.data.image)) {
          this.imageColorMap.set(
            node.data.image,
            this.generateColorFromHash(this.hashStringToNumber(node.data.image))
          );
        }

        return this.imageColorMap.get(node.data.image) as string;
      },
      renderLinks: true,
      linkWidthScale: 1,
      showFPSMonitor: false,
      events: {
        onClick: (node) => {
          this.selectedNode = node;
          if (node === undefined) {
            this.graph?.unselectNodes();
          } else {
            this.graph?.selectNodeById(node.id);
          }
        },
      },
    };

    // Build nodes and links from scenes
    const nodes = [];
    const links: Link[] = [];

    for (let sceneName in this.scenes) {
      nodes.push({ id: sceneName, data: this.scenes[sceneName] });

      if (this.scenes[sceneName].nextGraph !== undefined) {
        let targetScene = this.scenes[sceneName].nextGraph as string;
        links.push({ source: sceneName, target: targetScene });
        continue;
      }

      for (let i = 0; i < this.scenes[sceneName].choices.length; ++i) {
        let targetScene = this.scenes[sceneName].choices[i].next;
        if (!targetScene.startsWith("s:") && !targetScene.startsWith("g:"))
          continue;
        targetScene = targetScene.substring(2);

        links.push({ source: sceneName, target: targetScene });
      }
    }

    this.graph = new Graph<Node, Link>(
      document.getElementById("graph-canvas") as HTMLCanvasElement,
      config
    );

    this.graph.setData(nodes, links);
    this.graph.setZoomLevel(5);
  },
});
</script>

<style scoped lang="scss">
@import "../assets/colors";
@import "../assets/vars";

.container {
  position: relative;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

#graph-canvas {
  display: inherit;
  margin-top: 1em;
}

.zone-container {
  position: absolute;
  top: 0.4em;
  left: 0.4em;
  pointer-events: none;
  right: 50%;
}

.image {
  width: 300px;
  border-top-style: solid;
  border-top-width: 20px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  img {
    margin-top: 0.5em;
    width: 300px;
  }
}

.character {
  position: absolute;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  pointer-events: none;
}

.text {
  background-color: $colorFg1;
  color: $colorBg1;
  padding: 0.2em;
}

.choice {
  background-color: $colorBg2;
  padding: 0.2em;
  margin: 0.2em;
  cursor: pointer;
}

.inspect-container {
  color: white;
  position: absolute;
  top: 0;
  left: 800px + 10px;
}

.stats {
  font-size: 30px;
  color: white;
}
</style>

/**
 * Copyright (C) 2012 by Justin Windle
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */

import { randomFloat } from "./tools";

const settings = {
  interactive: false,
  darkTheme: true,
  headRadius: 20,
  thickness: 5,
  friction: 0.02,
  gravity: 0.03,
  colour: { h: 314, s: 0.8, v: 0.7 },
  length: 20,
  pulse: true,
  wind: 0,
};

class TentacleNode {
  x: number;
  y: number;

  ox: number;
  oy: number;

  vx: number;
  vy: number;

  constructor(x = 0, y = 0) {
    this.x = this.ox = x || 0.0;
    this.y = this.oy = y || 0.0;

    this.vx = 0.0;
    this.vy = 0.0;
  }
}

type Point = {
  x: number;
  y: number;
};

export class Tentacle {
  length: number;
  radius: number;
  spacing: number;
  friction: number;
  shade: number;
  nodes: TentacleNode[];
  outer: Point[];
  inner: Point[];
  theta: number[];

  constructor(options: any) {
    this.length = options.length || 10;
    this.radius = options.radius || 10;
    this.spacing = options.spacing || 20;
    this.friction = options.friction || 0.8;
    this.shade = 0.8;

    this.nodes = [];
    this.outer = [];
    this.inner = [];
    this.theta = [];

    for (let i = 0; i < this.length; i++) {
      this.nodes.push(new TentacleNode());
    }
  }

  move(x: number, y: number, instant: boolean) {
    this.nodes[0].x = x;
    this.nodes[0].y = y;

    if (instant) {
      let i, node;

      for (i = 1; i < this.length; i++) {
        node = this.nodes[i];
        node.x = x;
        node.y = y;
      }
    }
  }

  curveThroughPoints(points: Point[], ctx: any) {
    let i, n, a, b, x, y;

    for (i = 1, n = points.length - 2; i < n; i++) {
      a = points[i];
      b = points[i + 1];

      x = (a.x + b.x) * 0.5;
      y = (a.y + b.y) * 0.5;

      ctx.quadraticCurveTo(a.x, a.y, x, y);
    }

    a = points[i];
    b = points[i + 1];

    ctx.quadraticCurveTo(a.x, a.y, b.x, b.y);
  }

  update() {
    let s,
      c,
      dx,
      dy,
      da,
      px,
      py,
      node,
      prev = this.nodes[0];
    let radius = this.radius * settings.thickness;
    const step = radius / this.length;

    for (let i = 1, j = 0; i < this.length; i++, j++) {
      node = this.nodes[i];

      node.x += node.vx;
      node.y += node.vy;

      dx = prev.x - node.x;
      dy = prev.y - node.y;
      da = Math.atan2(dy, dx);

      px = node.x + Math.cos(da) * this.spacing * settings.length;
      py = node.y + Math.sin(da) * this.spacing * settings.length;

      node.x = prev.x - (px - node.x);
      node.y = prev.y - (py - node.y);

      node.vx = node.x - node.ox;
      node.vy = node.y - node.oy;

      node.vx *= this.friction * (1 - settings.friction);
      node.vy *= this.friction * (1 - settings.friction);

      node.vx += settings.wind;
      node.vy += settings.gravity;

      node.ox = node.x;
      node.oy = node.y;

      s = Math.sin(da + Math.PI / 2);
      c = Math.cos(da + Math.PI / 2);

      this.outer[j] = {
        x: prev.x + c * radius,
        y: prev.y + s * radius,
      };

      this.inner[j] = {
        x: prev.x - c * radius,
        y: prev.y - s * radius,
      };

      this.theta[j] = da;

      radius -= step;

      prev = node;
    }
  }

  draw(ctx: any) {
    const s = this.outer[0];
    const e = this.inner[0];

    if (s !== undefined && e !== undefined) {
      ctx.beginPath();
      ctx.moveTo(s.x, s.y);
      this.curveThroughPoints(this.outer, ctx);
      this.curveThroughPoints(this.inner.reverse(), ctx);
      ctx.lineTo(e.x, e.y);
      ctx.closePath();
    }

    const h = settings.colour.h * this.shade;
    const s2 = settings.colour.s * 100 * this.shade;
    let v = settings.colour.v * 100 * this.shade;

    ctx.fillStyle = "hsl(" + h + "," + s2 + "%," + v + "%)";
    ctx.fill();

    if (settings.thickness > 2) {
      v += settings.darkTheme ? -10 : 10;

      ctx.strokeStyle = "hsl(" + h + "," + s2 + "%," + v + "%)";
      ctx.lineWidth = 1;
      ctx.stroke();
    }
  }
}

<template>
  <span>
    <div
      id="container-combat"
      :class="`combat-container`"
      :style="{
        filter: `hue-rotate(${num.hueRotationDeg}deg) contrast(${num.contrast})`,
      }"
    >
      <span
        class="player-health dys-size"
        :style="isActive ? 'opacity:1' : 'transition-duration: 0s'"
        >Health points: {{ num.playerHealth }}</span
      >
      <img
        v-if="battles[battleName].background !== undefined && isActive"
        class="bg-img"
        id="bg-img-tuto"
        :src="`img/battle/backgrounds/${battles[battleName].background}${
          dyslexic ? '_dyslexic' : ''
        }.png`"
      />
    </div>
    <div
      id="blood-vignette"
      :style="`opacity: ${
        num.playerHealth <= 1 ? 1 : num.playerHealth <= 2 ? 0.2 : 0
      };`"
    ></div>
    <div
      class="debug-combat"
      v-if="showDebug"
      :style="!isActive ? 'pointer-events: none;' : ''"
    >
      <span class="debug-num">
        <button tabindex="-1" @click="reloadCombat">Reload combat</button>
      </span>
      <span class="debug-num"
        >audioCurrentTime: {{ currTime().toFixed(3) }}</span
      >
      <span class="debug-num">
        <button tabindex="-1" @click="shakeCanvas">shake canvas</button>
      </span>
      <span class="debug-num">nbBullets : {{ bullets.length }}</span>
      <span class="debug-num"
        ><input
          tabindex="-1"
          type="range"
          style="width: 90%"
          min="0"
          :max="audioSources.music_background.getDuration()"
          @change="changeAudioTimeEvent" /><br /><input
          tabindex="-1"
          type="range"
          disabled="true"
          style="width: 90%"
          min="0"
          :max="audioSources.music_background.getDuration()"
          :value="currTime()"
      /></span>
      <span class="debug-num">
        <button tabindex="-1" @click="singerSpawn">singer spawn fake</button>
      </span>
      <span class="debug-num">
        <button tabindex="-1" @click="singerSpawnReal">
          singer spawn real
        </button>
      </span>
      <span class="debug-num">
        <button tabindex="-1" @click="playerHurt">player hurt</button>
      </span>
      <span class="debug-num"> _ </span>
      <span class="debug-num"> _ </span>
      <!-- Display all numbers -->
      <span
        class="debug-num"
        v-for="(numVal, numName) in num"
        :key="numName"
        style="margin-right: 10px"
      >
        {{ numName.padEnd(25, "&nbsp;")
        }}{{ `${numVal >= 0 ? "&nbsp;" : ""}${numVal.toFixed(3)}` }}
      </span>
      <br />
    </div>
  </span>
</template>

<script lang="ts">
import { Particle } from "../sketch";
import { randomInt, randomFloat, IBattles, IPatternFlags } from "../tools";
import { defineComponent, PropType } from "vue";
import battlesJson from "../battles.json";
import { AudioSource } from "../AudioSource";
import { Bullet, BulletType } from "../Bullet";
import { Tentacle } from "../Tentacle";

// Constants
const TWO_PI = Math.PI * 2;
const MAX_PARTICLES = 280;
const TERRAIN_WIDTH = 60 * 16; // = 960 // MUST BE IN SYNC WITH DIV WIDTH // must not change...
const TERRAIN_HEIGHT = 17 * 16; // = 272 // MUST BE IN SYNC WITH DIV HEIGHT // must not change...
const TERRAIN_BULLET_LIMIT = 100; // Bullet zone before being considered OOB
const PARTICLE_SIM_SPEED = 1 / 120;
const BULLET_RADIUS_CHANGE = 0.2;
const DIAP_RANGE = 75;
const DIAP_ANGLE = 2.5; // radian
const DIAP_COOLDOWN = 0.5; // seconds
const DIAP_MOVE_FORCE = 20;
const DIAP_ANIMATION_SPEED = 15;
const PLAYER_FREEZE_TIME_SINGER = 2;
const PLAYER_HURT_COOLDOWN = 3;
const PLAYER_NO_MOVE_COOLDOWN = 0.3;
const PLAYER_SPEED = 130; // pixels per second
const PLAYER_RADIUS = 10; // pixels
const PLAYER_COLLISION_RADIUS = PLAYER_RADIUS - 2; // pixels
const PLAYER_DRAG = 11.5; // deccéleration
const PLAYER_PARRY_INERTIA = 1.7;
const PLAYER_HURT_INERTIA = 5;
const PLAYER_PARRY_WHOLE_INERTIA = 6;
const DIAP_ANIM_HISTORY_LENGTH = 5;
const DIAP_ANIM_HISTORY_STEP_MS = 0.01; // delay between every sample
const COLOR_L = "#E81788"; // music
const COLOR_R = "#17E877"; // anti-music
const AUDIO_SOURCE_NOTES_BUFFER = 5; // How many notes can be played in the same time
const CLAMP_ANIMATION_DURATION = 1;
const CLAMP_FORCE = 1.8;
const SINGER_TENTACLES_NUM = 10;
const SINGER_RADIUS = 13;
const SINGER_TENTACLE_STEP = TWO_PI / SINGER_TENTACLES_NUM;
const SINGER_ANIMATION_DURATION = 3;
const SINGER_ANIMATION_DURATION_POSSIBLE = 5;
const SINGER_X_MOVE_SPEED = 1.5;
const SINGER_Y_MOVE_DIST = 1;
const SINGER_OVERRIDE_CLAMP_LEFT = 250;
const SINGER_OVERRIDE_CLAMP_RIGHT = 710;
const SINGER_OVERRIDE_CLAMP_MIDDLE =
  SINGER_OVERRIDE_CLAMP_LEFT +
  (SINGER_OVERRIDE_CLAMP_RIGHT - SINGER_OVERRIDE_CLAMP_LEFT) / 2;
const SINGER_OVERRIDE_CLAMP_UP = -250;
const SINGER_OVERRIDE_CLAMP_DOWN = -110;
const SINGER_OVERRIDE_CLAMP_CENTER =
  SINGER_OVERRIDE_CLAMP_UP +
  (SINGER_OVERRIDE_CLAMP_DOWN - SINGER_OVERRIDE_CLAMP_UP) / 2;
const SINGER_OVERRIDE_SLOW_DOWN = 0.7;
const SINGER_OVERRIDE_SPEED_UP = 1.5;
const BULLET_SPAWN_SPACING = 40; // Used with anchor flag. Instead of spawning directly on corners, space it a little
const NOTES_STEP_X = 52;
const NOTES_STEP_Y = 33;
const AURA_SCALE_CHANGE = 0.07;
const GRENADE_YEET_FORCE = 200;
const DEATH_CIRCLE_RADIUS = 150;
const DEATH_CIRCLE_LIFETIME = 0.25;
const DEATH_CIRCLE_RADIUS_DEPLETION = -30;
const PARTITION_LINE_SPACING = 9;

// indexed by playerHealth-1
const PLAYER_RADIUS_CHANGE = [1.6, 1, 0.8];
const PLAYER_BEAT_SPEED = [0.007, 0.004, 0.003];
const PLAYER_COLOR = ["#ff8888", "#ffdddd", "#ffffff"];
const COLOURS = [
  "#69D2E7",
  "#A7DBD8",
  "#E0E4CC",
  "#F38630",
  "#FA6900",
  "#FF4E50",
  "#F9D423",
];

const keyboardLayouts = {
  left: ["KeyA", "KeyQ", "ArrowLeft"],
  right: ["KeyD", "ArrowRight"],
  up: ["KeyW", "KeyZ", "ArrowUp"],
  down: ["KeyS", "ArrowDown"],
} as {
  left: string[];
  right: string[];
  up: string[];
  down: string[];
};

const heartBeatFunction = (x: number) => Math.sin(Math.pow(8, Math.sin(x)));

type setHealthFunction = (health: number) => void;
type setStuffFunction = (stuff: any) => void;
type AudioSourceObj = {
  [key: string]: AudioSource;
};

export default defineComponent({
  name: "CombatCanvas",
  data() {
    return {
      deathCircles: [] as {
        x: number;
        y: number;
        radius: number;
        lifetime: number;
      }[],
      generatorTimeouts: [] as number[],
      ctx: {} as CanvasRenderingContext2D,
      canvas: {} as HTMLCanvasElement,
      intervalDraw: 0,
      intervalUpdate: 0,
      pool: [] as Particle[],
      particles: [] as Particle[],
      keyStates: new Map<string, boolean>(),
      cannotCatchSinger: false as boolean,
      singerTouched: false as boolean,
      nextSceneSinger: "" as string,
      clampDisapear: false as boolean,
      num: {
        mouseX: 0,
        playerX: TERRAIN_WIDTH / 2,
        playerMouseAngle: 0,

        mouseY: 0,
        playerY: TERRAIN_HEIGHT / 1.5,
        playerParryAngle: 0,

        diapCurrentAngle: 0,
        playerXGlobal: 0,
        playerDisplayRadius: 10,

        diapCoolDown: 0,
        playerYGlobal: 0,
        elapsedTime: 0,

        diapAnimation: 1,
        playerVx: 0,
        bulletDisplayScale: 1,

        playerHurtCoolDown: 0,
        playerVy: 0,
        playerHealth: 3,

        particleSimDelay: 0,
        singerX: SINGER_OVERRIDE_CLAMP_MIDDLE,
        playerNoMoveCooldown: 0,

        singerAnimation: 0,
        singerY: SINGER_OVERRIDE_CLAMP_CENTER,
        singerDisplayRadius: SINGER_RADIUS,

        singerSpawnYPos: 0,
        clampAnimation: 0,
        clampLeft: 0,

        clampRight: 0,
        clampTop: 0,
        clampBottom: 0,

        currentSingerAnimation: 0,
        singerOverrideDisplayTimeX: 1.57,
        singerOverrideDisplayTimeY: 0,

        someSinus: 0,
        hueRotationDeg: 0,
        contrast: 1,
      },
      singerTentacles: [] as Tentacle[],
      isPlayerLeft: false,
      diapAnimHistory: [] as number[],
      diapAnimHistoryPointer: 0,
      diapAnimHistoryLast: 0,
      lastFrameTime: +new Date(),
      battles: battlesJson as IBattles,
      audioWasUndefined: true as boolean,
      img_diapason: document.createElement("img") as HTMLImageElement,
      img_diapason_move_l: document.createElement("img") as HTMLImageElement,
      img_diapason_move_r: document.createElement("img") as HTMLImageElement,
      /*LEGACY*/
      // eslint-disable-next-line prettier/prettier
      // audioNotes: [ new AudioSource("soundfx/notes/tuba_note0.ogg"), new AudioSource("soundfx/notes/tuba_note10.ogg"), new AudioSource("soundfx/notes/tuba_note11.ogg"), new AudioSource("soundfx/notes/tuba_note12.ogg"), new AudioSource("soundfx/notes/tuba_note13.ogg"), new AudioSource("soundfx/notes/tuba_note14.ogg"), new AudioSource("soundfx/notes/tuba_note16.ogg"), new AudioSource("soundfx/notes/tuba_note17.ogg"), new AudioSource("soundfx/notes/tuba_note18.ogg"), new AudioSource("soundfx/notes/tuba_note19.ogg"), new AudioSource("soundfx/notes/tuba_note20.ogg"), new AudioSource("soundfx/notes/tuba_note21.ogg"), new AudioSource("soundfx/notes/tuba_note22.ogg"), new AudioSource("soundfx/notes/tuba_note23.ogg"), new AudioSource("soundfx/notes/tuba_note24.ogg"), new AudioSource("soundfx/notes/tuba_note25.ogg"), new AudioSource("soundfx/notes/tuba_note26.ogg"), new AudioSource("soundfx/notes/tuba_note27.ogg"), new AudioSource("soundfx/notes/tuba_note2.ogg"), new AudioSource("soundfx/notes/tuba_note3.ogg"), new AudioSource("soundfx/notes/tuba_note4.ogg"), new AudioSource("soundfx/notes/tuba_note5.ogg"), new AudioSource("soundfx/notes/tuba_note6.ogg"), new AudioSource("soundfx/notes/tuba_note7.ogg"), new AudioSource("soundfx/notes/tuba_note8.ogg"), new AudioSource("soundfx/notes/tuba_note9.ogg") ],
      // eslint-disable-next-line prettier/prettier
      // audioNotesAlt: [ new AudioSource("soundfx/notes/tuba_note_alt10.ogg"), new AudioSource("soundfx/notes/tuba_note_alt11.ogg"), new AudioSource("soundfx/notes/tuba_note_alt12.ogg"), new AudioSource("soundfx/notes/tuba_note_alt13.ogg"), new AudioSource("soundfx/notes/tuba_note_alt15.ogg"), new AudioSource("soundfx/notes/tuba_note_alt16.ogg"), new AudioSource("soundfx/notes/tuba_note_alt17.ogg"), new AudioSource("soundfx/notes/tuba_note_alt18.ogg"), new AudioSource("soundfx/notes/tuba_note_alt1.ogg"), new AudioSource("soundfx/notes/tuba_note_alt2.ogg"), new AudioSource("soundfx/notes/tuba_note_alt3.ogg"), new AudioSource("soundfx/notes/tuba_note_alt4.ogg"), new AudioSource("soundfx/notes/tuba_note_alt5.ogg"), new AudioSource("soundfx/notes/tuba_note_alt6.ogg"), new AudioSource("soundfx/notes/tuba_note_alt7.ogg"), new AudioSource("soundfx/notes/tuba_note_alt8.ogg"), new AudioSource("soundfx/notes/tuba_note_alt9.ogg") ],
      diapIsLeft: false as boolean,
      bullets: [] as Bullet[],
      patternStack: [] as {
        time: number;
        name: string;
        flags: IPatternFlags;
        options?: any;
      }[],
      attackPatterns: [] as any,
      patternFlags: {} as IPatternFlags,
      internalCombatContainer: null as HTMLDivElement | null,
    };
  },
  props: {
    dyslexic: {
      required: true,
      type: Boolean as PropType<boolean>,
    },
    isActive: {
      required: true,
      type: Boolean as PropType<boolean>,
    },
    reloadRequest: {
      required: true,
      type: Boolean as PropType<boolean>,
    },
    setHP: {
      required: true,
      type: Function as PropType<setHealthFunction>,
    },
    updateStuff: {
      required: true,
      type: Function as PropType<setStuffFunction>,
    },
    enableScreenShake: {
      required: true,
      type: Boolean as PropType<boolean>,
    },
    invincible: {
      required: true,
      type: Boolean as PropType<boolean>,
    },
    showDebug: {
      required: false,
      type: Boolean as PropType<boolean>,
      default: false,
    },
    battleName: {
      required: true,
      type: String,
    },
    battleTime: {
      required: true,
      type: Number,
    },
    overrideDisplaySinger: {
      required: true,
      type: Boolean as PropType<boolean>,
    },
    audioSources: {
      required: true,
      type: Object as PropType<AudioSourceObj>,
    },
    fxVolume: {
      required: true,
      type: Number,
    },
  },
  mounted() {
    this.randomizeTentacles();

    this.num.singerAnimation = 0;

    Bullet.terrainDimension.x = TERRAIN_WIDTH;
    Bullet.terrainDimension.y = TERRAIN_HEIGHT;

    this.audioSources.effects_playerHeartBeat.pause();

    // LEGACY
    // this.setVolumeNotes(this.fxVolume);

    // Pass data to bullet
    Bullet.global = this;
    Bullet.diap_range = DIAP_RANGE;
    Bullet.diap_angle = DIAP_ANGLE;

    // Load combat patterns
    this.patternStack = this.clone(this.battles[this.battleName].patterns);

    // Init diap anim array
    for (let i = 0; i < DIAP_ANIM_HISTORY_LENGTH; ++i) {
      this.diapAnimHistory[i] = 0;
    }

    this.attackPatterns = {
      generator: (options: {
        duration: number;
        delay: number;
        pattern: string;
        optionception: any;
      }) => {
        const flags = this.clone(this.patternFlags);
        for (let d = 0; d <= options.duration; d += options.delay) {
          this.generatorTimeouts.push(
            setTimeout(() => {
              this.patternFlags = flags;
              this.attackPatterns[options.pattern](options.optionception);
            }, d * 1000)
          );
        }
      },
      sector: (options: {
        rotation: number;
        rotationSpeed: number;
        wideness: number;
        layers: number;
        distIncrease: number;
        trippy: boolean;
        x: number;
        y: number;
        vx: number;
        vy: number;
      }) => {
        let distanceFromCenter = 0;

        for (let i = 0; i < options.layers; ++i) {
          const numBullets = Math.round(options.wideness * i) + 1;
          for (let j = 0; j < numBullets; ++j) {
            const scalar = j / numBullets;
            const angle =
              options.rotation -
              options.wideness / 2 +
              scalar * options.wideness;

            this.spawnBullet(
              options.x + Math.cos(angle) * distanceFromCenter,
              options.y + Math.sin(angle) * distanceFromCenter,
              options.vx,
              options.vy,
              0,
              (bullet: Bullet, dt: number) => {
                const centerX =
                  bullet.x -
                  Math.cos(bullet.customValues.rot) *
                    (options.trippy
                      ? distanceFromCenter
                      : bullet.customValues.rotDist);
                const centerY =
                  bullet.y -
                  Math.sin(bullet.customValues.rot) *
                    (options.trippy
                      ? distanceFromCenter
                      : bullet.customValues.rotDist);

                bullet.customValues.rot += bullet.customValues.rotSpeed * dt;

                bullet.x =
                  centerX +
                  Math.cos(bullet.customValues.rot) *
                    (options.trippy
                      ? distanceFromCenter
                      : bullet.customValues.rotDist);
                bullet.y =
                  centerY +
                  Math.sin(bullet.customValues.rot) *
                    (options.trippy
                      ? distanceFromCenter
                      : bullet.customValues.rotDist);
              },
              {
                rot: angle,
                rotSpeed: options.rotationSpeed ?? 0,
                rotDist: distanceFromCenter,
              }
            );
          }
          distanceFromCenter += options.distIncrease ?? 35;
        }
      },
      cone: (options: {
        x: number;
        y: number;
        vx: number;
        vy: number;
        rotation: number;
      }) => {
        for (let i = 0; i < 8; ++i) {
          // layers
          let half = i / 2;

          // White stripe in the middle of the cone
          this.patternFlags.isWhole = i === 5;

          // Add bullet on the sides for the last layer
          // for the base of the cone
          if (i == 7) half += 1;

          for (let j = 0; j < half * 2; ++j) {
            let vecX = (j - half) * 40;
            let vecY = i * 28;

            // Rotate vector
            const cos = Math.cos(options.rotation);
            const sin = Math.sin(options.rotation);
            const vecX2 = vecX * cos - vecY * sin;
            const vecY2 = vecX * sin + vecY * cos;

            // bullet per layer
            this.spawnBullet(
              options.x + vecX2,
              options.y + vecY2,
              options.vx,
              options.vy
            );
          }
        }
      },
      wall: (options: {
        isLeft: boolean;
        vel: number;
        needSide: number;
        pos: string;
      }) => {
        let yStart = 20;
        let yStop = 260;

        switch (options.pos) {
          case "t": // top
            yStop = 150;
            break;

          case "c": // center
            yStart = 100;
            yStop = 180;
            break;

          case "b": // bottom
            yStart = 150;
            break;
        }

        const x = options.isLeft
          ? -BULLET_SPAWN_SPACING
          : TERRAIN_WIDTH + BULLET_SPAWN_SPACING;
        const vx = options.isLeft ? options.vel : -options.vel;
        for (let y = yStart; y < yStop; y += NOTES_STEP_Y) {
          this.spawnBullet(x, y, vx, 0, options.needSide ?? 0);
        }
      },
      one: (options: { x: number; y: number; vx: number; vy: number }) => {
        this.spawnBullet(options.x, options.y, options.vx, options.vy);
      },
      matrix: (options: {
        x: number;
        y: number;
        sameX: boolean;
        sameY: boolean;
        vx: number;
        vy: number;
        lineDelay: number;
        data: number[][];
      }) => {
        const flags = this.clone(this.patternFlags);
        for (let y = 0; y < options.data.length; y++) {
          this.generatorTimeouts.push(
            setTimeout(() => {
              this.patternFlags = flags;
              for (let x = 0; x < options.data[y].length; x++) {
                if (options.data[y][x] === 1) {
                  this.spawnBullet(
                    options.sameX ? options.x : options.x + x * NOTES_STEP_X,
                    options.sameY ? options.y : options.y + y * NOTES_STEP_Y,
                    options.vx,
                    options.vy
                  );
                }
              }
            }, options.lineDelay * y * 1000)
          );
        }
      },
      square: (options: {
        x: number;
        y: number;
        w: number;
        h: number;
        vx: number;
        vy: number;
        mixed: number | undefined;
      }) => {
        let invert = 1;
        if (options.w < 0) {
          invert = -1;
        }
        for (
          let _x = options.x;
          options.w > 0
            ? _x < options.x + options.w
            : _x > options.x + options.w;
          _x += NOTES_STEP_X * invert
        ) {
          for (
            let _y = options.y;
            _y < options.y + options.h;
            _y += NOTES_STEP_Y
          ) {
            this.spawnBullet(_x, _y, options.vx, options.vy, options.mixed);
            if (options.mixed !== undefined) {
              // options.mixed cycles between 1 and 2
              options.mixed = (options.mixed % 2) + 1;
            }
          }
        }
      },
      filter: (options: { hue_rotation_deg: number; contrast: number }) => {
        this.num.hueRotationDeg = options.hue_rotation_deg;
        this.num.contrast = options.contrast;
      },
      specialWizard: (options: { isReverse: number }) => {
        const tl = options.isReverse ? "tr" : "tl";
        const tr = options.isReverse ? "tl" : "tr";
        const bl = options.isReverse ? "br" : "bl";
        const br = options.isReverse ? "bl" : "br";
        const cl = options.isReverse ? "cr" : "cl";
        const cr = options.isReverse ? "cl" : "cr";
        const dir = options.isReverse ? -1 : 1;

        const timeReverse = options.isReverse
          ? (time: number) => 15000 - time
          : (time: number) => time;

        const angleReverse = options.isReverse
          ? (angle: number) => 6.28 - angle
          : (angle: number) => angle;

        const flags = this.clone(this.patternFlags);
        const timeouts = [
          setTimeout(() => {
            this.patternFlags = { ...flags, anchor: cl, isWhole: true };
            this.attackPatterns.sector({
              x: -200 * dir,
              y: 0,
              rotation: angleReverse(1.57),
              rotationSpeed: 0.85 * dir,
              distIncrease: 45,
              wideness: 3.14,
              layers: 4,
              vx: 120 * dir,
              vy: 0,
            });
          }, timeReverse(3000)),
          setTimeout(() => {
            this.patternFlags = { ...flags, anchor: cl };
            this.attackPatterns.sector({
              x: -200 * dir,
              y: 0,
              rotation: angleReverse(4.71),
              rotationSpeed: 0.85 * dir,
              distIncrease: 45,
              wideness: 3.14,
              layers: 4,
              vx: 120 * dir,
              vy: 0,
            });
          }, timeReverse(3000)),

          setTimeout(() => {
            this.patternFlags = { ...flags, anchor: cl };
            this.attackPatterns.sector({
              x: -200 * dir,
              y: 0,
              rotation: angleReverse(0),
              rotationSpeed: 0.85 * dir,
              distIncrease: 45,
              wideness: 1.256,
              layers: 4,
              vx: 120 * dir,
              vy: 0,
            });
          }, timeReverse(7000)),
          setTimeout(() => {
            this.patternFlags = { ...flags, anchor: cl, isWhole: true };
            this.attackPatterns.sector({
              x: -200 * dir,
              y: 0,
              rotation: angleReverse(1.57),
              rotationSpeed: 0.85 * dir,
              distIncrease: 45,
              wideness: 1.884,
              layers: 4,
              vx: 120 * dir,
              vy: 0,
            });
          }, timeReverse(7000)),
          setTimeout(() => {
            this.patternFlags = { ...flags, anchor: cl };
            this.attackPatterns.sector({
              x: -200 * dir,
              y: 0,
              rotation: angleReverse(3.14),
              rotationSpeed: 0.85 * dir,
              distIncrease: 45,
              wideness: 1.256,
              layers: 4,
              vx: 120 * dir,
              vy: 0,
            });
          }, timeReverse(7000)),
          setTimeout(() => {
            this.patternFlags = { ...flags, anchor: cl, isWhole: true };
            this.attackPatterns.sector({
              x: -200 * dir,
              y: 0,
              rotation: angleReverse(4.71),
              rotationSpeed: 0.85 * dir,
              distIncrease: 45,
              wideness: 1.884,
              layers: 4,
              vx: 120 * dir,
              vy: 0,
            });
          }, timeReverse(7000)),

          setTimeout(() => {
            this.patternFlags = { ...flags, anchor: cl, isWhole: true };
            this.attackPatterns.sector({
              x: -200 * dir,
              y: 0,
              trippy: true,
              rotation: 0,
              rotationSpeed: 0.8 * dir,
              distIncrease: 28,
              wideness: 6.28,
              layers: 3,
              vx: 120 * dir,
              vy: 0,
            });
          }, timeReverse(11000)),

          setTimeout(() => {
            this.patternFlags = {
              ...flags,
              anchor: cl,
            };
            this.attackPatterns.sector({
              x: -200 * dir,
              y: this.num.playerY - 110,
              rotation: 0,
              rotationSpeed: 25,
              distIncrease: 40,
              wideness: 6.28,
              layers: 2,
              vx: 500 * dir,
              vy: 0,
            });
          }, timeReverse(15000)),
        ];
      },
      bigBallColored: (options: {
        nbSlices: number;
        x: number;
        y: number;
        vx: number;
        vy: number;
      }) => {
        const timeouts = [];
        const flags = this.clone(this.patternFlags);
        // Big ball at the end
        for (let i = 0; i < options.nbSlices; ++i) {
          if (i % 2 == 0) {
            this.patternFlags = {
              ...flags,
              needRightParry: true,
            };
          } else {
            this.patternFlags = {
              ...flags,
              needLeftParry: true,
            };
          }
          this.attackPatterns.sector({
            x: options.x,
            y: options.y,
            rotation: i * ((2 * 3.14) / options.nbSlices),
            wideness: (2 * 3.14) / options.nbSlices,
            layers: 5,
            vx: options.vx,
            vy: options.vy,
          });
        }
      },
      partitionClamp: (options: {
        left: number;
        right: number;
        top: number;
        bottom: number;
      }) => {
        this.num.clampAnimation = CLAMP_ANIMATION_DURATION;
        if (
          options.left == 0 &&
          options.right == 0 &&
          options.top == 0 &&
          options.bottom == 0
        ) {
          this.clampDisapear = true;
        } else {
          this.num.clampLeft = options.left;
          this.num.clampRight = options.right;
          this.num.clampTop = options.top;
          this.num.clampBottom = options.bottom;
        }
      },
      lineAimed: (options: {
        x: number;
        y: number;
        vel: number;
        count: number;
      }) => {
        // Adjust position with anchor flag
        let pos: any = options;
        if (this.patternFlags.anchor !== undefined) {
          pos = this.adjustPosFromAnchor(options, this.patternFlags.anchor);
        }

        const angle = Math.atan2(
          pos.y - this.num.playerY,
          pos.x - this.num.playerX
        );
        const cosinused = Math.cos(angle);
        const sinused = Math.sin(angle);
        for (let i = 0; i < options.count; ++i) {
          this.spawnBullet(
            options.x + cosinused * i * 50,
            options.y + sinused * i * 50,
            cosinused * options.vel * -1,
            -sinused * options.vel
          );
        }
      },
      lineFromTop: (options: { vel: number; pos: string }) => {
        let x = 60;
        let w = 850;

        switch (options.pos) {
          case "l": // left
            x = 50;
            w = 220;
            break;

          case "m": // middle
            x = 323;
            w = 340;
            break;

          case "r": // right
            x = 700;
            w = 220;
            break;

          case "bl": // big left
            x = 50;
            w = 400;
            break;

          case "br": // big right
            x = 540;
            w = 400;
            break;

          case "wl": // whole left
            x = 40;
            w = 430;
            break;

          case "wr": // whole right
            x = 510;
            w = 430;
            break;
        }

        this.attackPatterns.square({
          x,
          y: -50,
          w,
          h: 1,
          vx: 0,
          vy: options.vel,
        });
      },
      singerSpawn: (options: { cannotCatch: boolean; nextScene: string }) => {
        this.singerSpawn(null, options.cannotCatch, options.nextScene);
      },
      sprinkle: (options: {
        x: number;
        y: number;
        duration: number;
        delay: number;
        speed: number;
        angleStep: number;
        angleStart: number;
        clockwise: boolean;
      }) => {
        const flags = this.clone(this.patternFlags);
        let i = 0;
        for (let d = 0; d <= options.duration; d += options.delay) {
          const vx =
            Math.sin(options.angleStart + options.angleStep * i) *
            options.speed;
          const vy =
            Math.cos(options.angleStart + options.angleStep * i) *
            options.speed;
          this.generatorTimeouts.push(
            setTimeout(() => {
              this.patternFlags = flags;
              this.spawnBullet(options.x, options.y, vx, vy);
            }, d * 1000)
          );
          i = options.clockwise ? i - 1 : i + 1;
        }
      },
    };

    window.onmousemove = (e: MouseEvent) => {
      this.num.mouseX = e.clientX + scrollX;
      this.num.mouseY = e.clientY + scrollY;

      this.computePlayerMouseAngle();
    };
    window.onmousedown = (e: MouseEvent) => {
      if (!this.isActive || this.num.playerHealth <= 0) return;
      this.playerParry();
    };

    // Get canvas and its rendering context
    const canvasElement = document.getElementById(
      "combat-canvas"
    ) as HTMLCanvasElement | null;
    if (canvasElement === null) {
      throw Error("Cannot get combat-canvas html element");
    }
    this.canvas = canvasElement;
    const context = this.canvas.getContext("2d");
    if (context === null) {
      throw Error("Cannot get combat-canvas 2d rendering context");
    }
    this.ctx = context;

    this.updateCanvasDimensions();

    Bullet.ctx = this.ctx;

    this.img_diapason.src = "img/battle/diapason.png";
    this.img_diapason_move_l.src = "img/battle/diapason_move_l.png";
    this.img_diapason_move_r.src = "img/battle/diapason_move_r.png";

    this.intervalUpdate = setInterval(this.update, 10);
    this.intervalDraw = setInterval(this.draw, 20);

    window.addEventListener("resize", this.updateCanvasDimensions);
    document.addEventListener("keydown", this.keyDown);
    document.addEventListener("keyup", this.keyUp);
  },
  unmounted() {
    this.audioSources.music_background.pause();
    this.audioSources.effects_grenadeCountdown.pause();

    clearInterval(this.intervalUpdate);
    clearInterval(this.intervalDraw);
    window.removeEventListener("resize", this.updateCanvasDimensions);
    document.removeEventListener("keydown", this.keyDown);
    document.removeEventListener("keyup", this.keyUp);
  },
  watch: {
    /* LEGACY
    fxVolume(current, old) {
      if (current != old) {
        this.setVolumeNotes(current);
      }
    },
    */
    isActive(current, old) {
      if (current != old) {
        if (current === false) {
          // Battle finished
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          this.audioSources.effects_playerHeartBeat.pause();
          this.num.playerHealth = 3;
        } else {
          this.reloadCombat();
        }
      }
    },
    // When this values changes, we reload the combat
    reloadRequest(current, old) {
      if (current != old) {
        this.reloadCombat();
      }
    },
  },
  methods: {
    randomizeTentacles() {
      this.singerTentacles = [];
      for (let i = 0; i < SINGER_TENTACLES_NUM; ++i) {
        this.singerTentacles.push(
          new Tentacle({
            length: randomFloat(6, 9),
            radius: randomFloat(0.3, 0.8),
            spacing: randomFloat(0.7, 1.0),
            friction: randomFloat(0.9, 0.95),
          })
        );
      }
    },
    playerHurt() {
      this.num.playerHurtCoolDown = PLAYER_HURT_COOLDOWN;
      this.shakeCanvas();
      // oof
      this.audioSources.effects_playerHurt.play();

      if (this.invincible) return;

      this.num.playerHealth -= 1;
      // Heart beat sound if 1 hp left
      if (this.num.playerHealth <= 1) {
        this.audioSources.effects_playerHeartBeat.play();
      }

      this.setHP(this.num.playerHealth);
    },
    changeAudioTimeEvent(event: any) {
      this.reloadCombat();

      const newTime = event.target.valueAsNumber;
      this.audioSources.music_background.setCurrentTime(newTime);

      // Delete all pattern before new time
      for (let i = this.patternStack.length - 1; i >= 0; --i) {
        if (newTime > this.patternStack[i].time) this.patternStack.pop();
      }

      // Clear generator timeouts
      this.generatorTimeouts.forEach(clearTimeout);
    },
    /*setVolumeNotes(volume: number) {
      // LEGACY
      // Update volume of every notes
      for (let i = 0; i < this.audioNotes.length; ++i) {
        this.audioNotes[i].setVolume(volume);
      }
      for (let i = 0; i < this.audioNotesAlt.length; ++i) {
        this.audioNotesAlt[i].setVolume(volume);
      }
    },*/
    isSingerFullyVisible() {
      return this.num.singerAnimation > 0;
    },
    async singerSpawnReal(
      event: any,
      cannotCatch = false,
      nextScene = "tuba_lunatic_singer"
    ) {
      this.singerSpawn(event, cannotCatch, nextScene);
    },
    async singerSpawn(event: any, cannotCatch = true, nextScene = "") {
      this.nextSceneSinger = nextScene;
      this.randomizeTentacles();
      this.audioSources.effects_singerSpawn.play();

      // Wait a bit
      await new Promise((resolve) => setTimeout(resolve, 3000));

      this.num.currentSingerAnimation = cannotCatch
        ? SINGER_ANIMATION_DURATION
        : SINGER_ANIMATION_DURATION_POSSIBLE;

      this.num.singerAnimation = this.num.currentSingerAnimation;
      this.cannotCatchSinger = cannotCatch;

      this.isPlayerLeft = this.num.playerX < TERRAIN_WIDTH / 2;
      this.num.singerSpawnYPos = this.num.playerY;
      this.num.singerX = this.isPlayerLeft ? TERRAIN_WIDTH : 0;
    },
    async shakeCanvas() {
      if (!this.enableScreenShake) return;
      this.canvas.classList.remove("canvas-shake");
      this.canvas.classList.remove("small-canvas-shake");
      // Arnaque moldave to make it work
      await new Promise((r) => setTimeout(r, 50));
      this.canvas.classList.add("canvas-shake");
    },
    async smallShakeCanvas() {
      this.canvas.classList.remove("canvas-shake");
      this.canvas.classList.remove("small-canvas-shake");
      // Arnaque moldave to make it work
      await new Promise((r) => setTimeout(r, 50));
      this.canvas.classList.add("small-canvas-shake");
    },
    clone(obj: any) {
      if (null == obj || "object" != typeof obj) return obj;
      var copy = obj.constructor();
      for (var attr in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
      }
      return copy;
    },
    reloadCombat() {
      this.isPlayerLeft = false;
      this.diapIsLeft = false;
      this.num.diapAnimation = -1;
      this.num.diapCoolDown = 0;
      this.num.playerVx = 0;
      this.num.playerVy = 0;
      this.num.playerHealth = 3;
      this.num.playerHurtCoolDown = 0;
      this.audioSources.effects_grenadeCountdown.pause();
      this.audioSources.effects_playerHeartBeat.pause();
      this.patternStack = this.clone(this.battles[this.battleName].patterns);
      this.audioSources.music_background.setCurrentTime(this.battleTime);
      this.audioSources.music_background.play();
      this.bullets = [];
      this.deathCircles = [];
      this.num.clampLeft = 0;
      this.num.clampRight = 0;
      this.num.clampTop = 0;
      this.num.clampBottom = 0;
      this.num.clampAnimation = 0;
      this.clampDisapear = false;
      this.num.hueRotationDeg = 0;
      this.num.contrast = 1;

      // Reload battle background animation
      const el = document.getElementById("bg-img-tuto") as any;
      if (el !== null) {
        el.style.animation = "none";
        void el.offsetWidth; /* trigger reflow */
        el.style.animation = null;
      }

      // Set bullet color
      Bullet.changeColor(this.battles[this.battleName].bulletColor);

      // Put player in centerish
      this.num.playerX = TERRAIN_WIDTH / 2;
      this.num.playerY = TERRAIN_HEIGHT / 1.5;

      this.computePlayerMouseAngle();
      this.num.diapCurrentAngle = this.num.playerMouseAngle;

      // Delete all pattern before the battle time
      for (let i = this.patternStack.length - 1; i >= 0; --i) {
        if (this.battleTime > this.patternStack[i].time) {
          this.patternStack.pop();
        }
      }

      // Clear generator timeouts
      this.generatorTimeouts.forEach(clearTimeout);
    },
    currTime() {
      try {
        return this.audioSources.music_background.getCurrentTime();
      } catch (e) {
        return 0;
      }
    },
    adjustPosFromAnchor(posParam: { x: number; y: number }, anchor: string) {
      // Exemple: br will set anchor to bottom right
      const pos = { x: posParam.x, y: posParam.y };

      switch (anchor[0]) {
        case "t": // top
          pos.y -= BULLET_SPAWN_SPACING;
          break;
        case "c": // center
          pos.y += TERRAIN_HEIGHT / 2;
          break;
        case "b": // bottom
          pos.y += TERRAIN_HEIGHT + BULLET_SPAWN_SPACING;
          break;
      }

      switch (anchor[1]) {
        case "l": // left
          pos.x -= BULLET_SPAWN_SPACING;
          break;
        case "m": // middle
          pos.x += TERRAIN_WIDTH / 2;
          break;
        case "r": // right
          pos.x += TERRAIN_WIDTH + BULLET_SPAWN_SPACING;
          break;
      }

      return pos;
    },
    spawnBullet(
      x: number,
      y: number,
      vx = 0,
      vy = 0,
      needSide = 0,
      customUpdate = (bullet: Bullet, dt: number) => {},
      customValues = {} as any
    ) {
      if (!this.isActive || this.num.playerHealth < 1) return;

      const b = new Bullet();
      b.x = x;
      b.y = y;
      b.vx = vx;
      b.vy = vy;
      b.customUpdate = customUpdate;
      b.customValues = customValues;

      // Set need side according to needLeftParry or needRightParry flag
      if (needSide !== 0) {
        b.needSide = needSide;
      } else if (this.patternFlags.needLeftParry === true) {
        b.needSide = 1;
      } else if (this.patternFlags.needRightParry === true) {
        b.needSide = 2;
      }

      // Set bullet type with isWhole flag
      if (this.patternFlags.isWhole === true) {
        b.type = BulletType.whole;
      } else if (this.patternFlags.isGrenade === true) {
        b.type = BulletType.grenade;
      } else {
        b.type = BulletType.half;
      }

      // Adjust position with anchor flag
      if (this.patternFlags.anchor !== undefined) {
        const pos = this.adjustPosFromAnchor(b, this.patternFlags.anchor);
        b.x = pos.x;
        b.y = pos.y;
      }

      if (b.type === BulletType.grenade) {
        b.countdownAudioId = this.audioSources.effects_grenadeCountdown.play();
      }

      this.bullets.push(b);

      return b;
    },
    addDeathCircle(x: number, y: number) {
      // Collision with bullets
      for (let i = 0; i < this.bullets.length; ++i) {
        if (
          this.circle2Circle(
            x,
            y,
            DEATH_CIRCLE_RADIUS,
            this.bullets[i].x,
            this.bullets[i].y,
            Bullet.radius
          )
        ) {
          if (this.bullets[i].type === BulletType.grenade) {
            this.bullets[i].detonate();
          } else {
            this.bullets[i].kill();
          }
        }
      }

      // Collision with player
      if (
        !this.invincible &&
        this.circle2Circle(
          x,
          y,
          DEATH_CIRCLE_RADIUS,
          this.num.playerX,
          this.num.playerY,
          PLAYER_COLLISION_RADIUS
        )
      ) {
        // Death circle is an instant kill
        this.num.playerHealth = 1;
        this.playerHurt();
      }

      // Add death circle
      this.deathCircles.push({
        x,
        y,
        radius: DEATH_CIRCLE_RADIUS,
        lifetime: DEATH_CIRCLE_LIFETIME,
      });
    },
    getContainerClientRect() {
      // refs doesn't work...
      if (this.internalCombatContainer === null) {
        this.internalCombatContainer = document.getElementById(
          "container-combat"
        ) as any;
      }
      if (this.internalCombatContainer === null) {
        alert("CANNOT GET COMBAT CONTAINER");
      }
      return this.internalCombatContainer?.getBoundingClientRect() as DOMRect;
    },
    computePlayerGlobalPos(rect: DOMRect) {
      this.num.playerXGlobal = rect.x + scrollX + this.num.playerX;
      this.num.playerYGlobal = rect.y + scrollY + this.num.playerY;
    },
    computePlayerMouseAngle() {
      // Compute player-mouse angle
      const dx = this.num.mouseX - this.num.playerXGlobal;
      const dy = this.num.mouseY - this.num.playerYGlobal;
      this.num.playerMouseAngle = Math.atan2(dy, dx);
    },
    clampLoop(val: number, min: number, max: number) {
      const delta = max - min;
      while (val > max) val -= delta;
      while (val < min) val += delta;

      return val;
    },
    spawnParticlesInFrontOfPlayer(color = "#ffffff", initialForce = 5) {
      // Particles in front of player
      const dx = this.num.mouseX - this.num.playerXGlobal;
      const dy = this.num.mouseY - this.num.playerYGlobal;
      const dist = Math.sqrt(dx * dx + dy * dy) / 40;
      const px = this.num.playerXGlobal + dx / dist;
      const py = this.num.playerYGlobal + dy / dist;
      for (let i = 0; i < 10; ++i) {
        this.spawnParticle(
          px + randomFloat(-20, 20),
          py + randomFloat(-20, 20),
          {
            color,
            radius: randomFloat(2, 5),
            initialForce,
            initialDirection:
              Math.PI * 2 -
              this.num.playerMouseAngle +
              Math.PI * (this.diapIsLeft ? 1 : 0),
            drag: randomFloat(0.96, 0.98),
            wander: randomFloat(0.2, 0.5),
          }
        );
      }
    },
    async initSingerTalk() {
      if (this.nextSceneSinger === "") return;
      this.singerTouched = true;

      const oldHue = this.num.hueRotationDeg;
      const oldContrast = this.num.contrast;
      this.num.hueRotationDeg = 80;

      // Stop the music
      this.audioSources.music_background.pause();
      this.audioSources.music_background.setCurrentTime(this.currTime()); // lmao I don't know why this is needed

      // Heal player to max
      this.num.playerHealth = 3;

      // Stop the singer's singing
      this.audioSources.effects_singerSpawn.pause();

      // Freeze the movement of the player
      this.num.playerNoMoveCooldown = PLAYER_FREEZE_TIME_SINGER;
      this.num.diapCoolDown = PLAYER_FREEZE_TIME_SINGER;

      // Wait a bit
      await new Promise((resolve) =>
        setTimeout(resolve, PLAYER_FREEZE_TIME_SINGER * 1000)
      );

      this.singerTouched = false;

      this.num.hueRotationDeg = oldHue;
      this.num.contrast = oldContrast;

      // Play the "horrible transition" video
      const videoHorribleTrans = document.getElementById(
        "horrible-transition"
      ) as HTMLVideoElement;
      videoHorribleTrans.style.opacity = "1";
      this.updateStuff({
        showBattle: false,
        battleActive: false,
        nextScene: this.nextSceneSinger,
      });
      videoHorribleTrans.play();

      this.randomizeTentacles();

      // Wait for the video to end
      await new Promise((resolve) => {
        videoHorribleTrans.onended = resolve;
      });

      // Hide the video
      videoHorribleTrans.style.opacity = "0";

      this.num.singerAnimation = 0;

      this.num.singerX =
        (SINGER_OVERRIDE_CLAMP_LEFT + SINGER_OVERRIDE_CLAMP_RIGHT) / 2;
      this.num.singerY =
        (SINGER_OVERRIDE_CLAMP_DOWN + SINGER_OVERRIDE_CLAMP_UP) / 2;
    },
    playerParry() {
      // Need to wait
      if (this.num.diapCoolDown > 0) return;

      this.audioSources.effects_playerParry.play();

      // Handle cooldowns
      this.num.playerNoMoveCooldown = PLAYER_NO_MOVE_COOLDOWN;
      this.num.diapCoolDown = DIAP_COOLDOWN;

      // Static parry angle
      this.num.playerParryAngle = this.num.playerMouseAngle;

      // Change diapason side
      this.diapIsLeft = !this.diapIsLeft;

      // Test if we yeet a bullet
      let triedToYeetWhole = false;
      let triedToYeetWrongColor = false;
      // LEGACY
      // let altChoosen = null as boolean | null;
      // let audioToPlay = [] as number[];
      for (let i = 0; i < this.bullets.length; ++i) {
        if (this.bullets[i].isDying()) continue;

        const dx = this.bullets[i].x - this.num.playerX;
        const dy = this.bullets[i].y - this.num.playerY;
        const dist = Math.sqrt(dx * dx + dy * dy);

        // If too far, ignore it
        if (dist > DIAP_RANGE) continue;

        let angle = Math.atan2(dy, dx);

        const vecPlayerX = Math.cos(this.num.playerParryAngle);
        const vecPlayerY = Math.sin(this.num.playerParryAngle);

        const vecBulletX = Math.cos(angle);
        const vecBulletY = Math.sin(angle);

        const dot = vecBulletX * vecPlayerX + vecBulletY * vecPlayerY;
        const truc = Math.sqrt(vecBulletX ** 2 + vecBulletY ** 2);
        const muche = Math.sqrt(vecPlayerX ** 2 + vecPlayerY ** 2);
        const shootAngle = Math.acos(dot / (truc * muche));

        // If out of the angle, ignore it
        if (shootAngle > DIAP_ANGLE / 2) continue;

        // Repel if whole bullet
        if (this.bullets[i].type === BulletType.whole) {
          this.spawnParticlesInFrontOfPlayer(
            "#" + this.battles[this.battleName].bulletColor,
            -2
          );
          triedToYeetWhole = true;
          continue;
        }

        // Repel if wrong color
        if (
          (this.bullets[i].needSide === 1 && !this.diapIsLeft) ||
          (this.bullets[i].needSide === 2 && this.diapIsLeft)
        ) {
          this.spawnParticlesInFrontOfPlayer(
            this.bullets[i].needSide === 1 ? COLOR_L : COLOR_R,
            -2
          );
          triedToYeetWrongColor = true;
          continue;
        }

        // Change inertia if bullet is grenade
        if (this.bullets[i].type === BulletType.grenade) {
          this.spawnParticlesInFrontOfPlayer(
            "#" + this.battles[this.battleName].bulletColor,
            -2
          );

          this.audioSources.effects_grenadeYeet.play();

          const bulletVel = Math.sqrt(
            this.bullets[i].vx ** 2 + this.bullets[i].vy ** 2
          );
          const yeetForce = Math.max(bulletVel / 2, GRENADE_YEET_FORCE);

          // Set inertia of the bullet
          this.bullets[i].vx = Math.cos(this.num.playerParryAngle) * yeetForce;
          this.bullets[i].vy = Math.sin(this.num.playerParryAngle) * yeetForce;
          continue;
        }

        // yeet
        this.spawnParticlesInFrontOfPlayer(
          "#" + this.battles[this.battleName].bulletColor
        );
        this.audioSources.effects_noteYeet.play();

        // LEGACY Select a yeet sound (so it makes a bit of a chord)
        /*
        if (altChoosen === null) {
          altChoosen = randomInt(0, 1) === 0 ? true : false;
        }
        if (altChoosen === true) {
          if (audioToPlay.length === 0) {
            audioToPlay.push(randomInt(0, this.audioNotesAlt.length - 1));
          } else {
            let p = audioToPlay[audioToPlay.length - 1];
            if (p + 3 <= this.audioNotesAlt.length - 1) {
              p += 3;
            } else {
              p -= 3;
            }
            audioToPlay.push(p);
          }
        } else if (altChoosen === false) {
          if (audioToPlay.length === 0) {
            audioToPlay.push(randomInt(0, this.audioNotes.length - 1));
          } else {
            let p = audioToPlay[audioToPlay.length - 1];
            if (p + 3 <= this.audioNotes.length - 1) {
              p += 3;
            } else {
              p -= 3;
            }
            audioToPlay.push(p);
          }
        }
        */
        this.bullets[i].kill();
      }

      // LEGACY
      /*
      if (altChoosen) {
        for (let i = 0; i < audioToPlay.length; ++i) {
          this.audioNotesAlt[audioToPlay[i]].play();
        }
      } else {
        for (let i = 0; i < audioToPlay.length; ++i) {
          this.audioNotes[audioToPlay[i]].play();
        }
      }
      */

      if (triedToYeetWhole) {
        this.smallShakeCanvas();
        this.audioSources.effects_parryDeny.play();
      }

      if (triedToYeetWrongColor) {
        this.smallShakeCanvas();
        this.audioSources.effects_colorDeny.play();
      }

      // Different inertia if a whole has been touched
      const parryInertia =
        triedToYeetWhole || triedToYeetWrongColor
          ? PLAYER_PARRY_WHOLE_INERTIA
          : PLAYER_PARRY_INERTIA;

      // If no whole touched, add inertia in the direction of the parry
      this.num.playerVx = -Math.cos(this.num.playerParryAngle) * parryInertia;
      this.num.playerVy = -Math.sin(this.num.playerParryAngle) * parryInertia;

      // Collision with the singer
      const dx = this.num.singerX - this.num.playerX;
      const dy = this.num.singerY - this.num.playerY;
      const dist = Math.sqrt(dx * dx + dy * dy);
      // If singer is in range of the diapason
      if (dist < DIAP_RANGE && this.num.singerAnimation > 0) {
        this.initSingerTalk();
      }
    },
    // Fetch key events
    keyDown(e: KeyboardEvent) {
      if (keyboardLayouts.left.includes(e.code))
        this.keyStates.set("left", true);
      if (keyboardLayouts.right.includes(e.code))
        this.keyStates.set("right", true);
      if (keyboardLayouts.up.includes(e.code)) this.keyStates.set("up", true);
      if (keyboardLayouts.down.includes(e.code))
        this.keyStates.set("down", true);
    },
    keyUp(e: KeyboardEvent) {
      if (keyboardLayouts.left.includes(e.code))
        this.keyStates.set("left", false);
      if (keyboardLayouts.right.includes(e.code))
        this.keyStates.set("right", false);
      if (keyboardLayouts.up.includes(e.code)) this.keyStates.set("up", false);
      if (keyboardLayouts.down.includes(e.code))
        this.keyStates.set("down", false);
    },

    clampPlayerPos() {
      // Map clamp
      if (this.num.playerX < PLAYER_COLLISION_RADIUS)
        this.num.playerX = PLAYER_COLLISION_RADIUS;
      if (this.num.playerY < PLAYER_COLLISION_RADIUS)
        this.num.playerY = PLAYER_COLLISION_RADIUS;
      if (this.num.playerX > TERRAIN_WIDTH - PLAYER_COLLISION_RADIUS)
        this.num.playerX = TERRAIN_WIDTH - PLAYER_COLLISION_RADIUS;
      if (this.num.playerY > TERRAIN_HEIGHT - PLAYER_COLLISION_RADIUS)
        this.num.playerY = TERRAIN_HEIGHT - PLAYER_COLLISION_RADIUS;

      // Partition clamp
      if (this.num.playerX < PLAYER_COLLISION_RADIUS + this.num.clampLeft)
        this.num.playerX = this.bringTo(
          this.num.playerX,
          PLAYER_COLLISION_RADIUS + this.num.clampLeft,
          CLAMP_FORCE
        );
      if (this.num.playerY < PLAYER_COLLISION_RADIUS + this.num.clampTop)
        this.num.playerY = this.bringTo(
          this.num.playerY,
          PLAYER_COLLISION_RADIUS + this.num.clampTop,
          CLAMP_FORCE
        );
      if (
        this.num.playerX >
        TERRAIN_WIDTH - PLAYER_COLLISION_RADIUS + this.num.clampRight
      )
        this.num.playerX = this.bringTo(
          this.num.playerX,
          TERRAIN_WIDTH - PLAYER_COLLISION_RADIUS + this.num.clampRight,
          CLAMP_FORCE
        );
      if (
        this.num.playerY >
        TERRAIN_HEIGHT - PLAYER_COLLISION_RADIUS + this.num.clampBottom
      )
        this.num.playerY = this.bringTo(
          this.num.playerY,
          TERRAIN_HEIGHT - PLAYER_COLLISION_RADIUS + this.num.clampBottom,
          CLAMP_FORCE
        );
    },
    drawDiapason(
      image: HTMLImageElement,
      x: number,
      y: number,
      cx: number,
      cy: number,
      scale: number,
      rotation: number,
      diapAnim: number
    ) {
      this.ctx.setTransform(scale, 0, 0, scale, x, y); // sets scale and origin
      this.ctx.rotate(rotation);
      this.ctx.translate(-10 * diapAnim, 0);
      this.ctx.rotate(-1 * diapAnim); // rotation is between -1 and 1 radian
      this.ctx.translate(10 * diapAnim, 0);
      this.ctx.drawImage(image, -cx, -cy);
      this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    },
    bringTo(current: number, target: number, force: number) {
      if (current < target) {
        current += force;
        if (current > target) current = target;
      }
      if (current > target) {
        current -= force;
        if (current < target) current = target;
      }
      return current;
    },
    circle2Circle(
      x: number,
      y: number,
      rad: number,
      x2: number,
      y2: number,
      rad2: number
    ) {
      return (x - x2) ** 2 + (y - y2) ** 2 <= (rad + rad2) ** 2;
    },
    updateCanvasDimensions() {
      this.canvas.width = document.body.scrollWidth;
      this.canvas.height = document.body.scrollHeight;
    },
    async update() {
      if (this.overrideDisplaySinger) {
        // Compute seconds elapsed since last frame
        const deltaTimeSec = (+new Date() - this.lastFrameTime) / 1000.0;
        this.lastFrameTime = +new Date();
        this.num.elapsedTime += deltaTimeSec;
        this.num.someSinus = Math.sin(this.num.elapsedTime * 5);
        this.num.particleSimDelay += deltaTimeSec;

        this.num.singerOverrideDisplayTimeX += 0.01 * Math.random() * 1.5;
        this.num.singerOverrideDisplayTimeY += 0.03 * Math.random() * 2;

        if (this.num.particleSimDelay > PARTICLE_SIM_SPEED) {
          this.num.singerDisplayRadius =
            SINGER_RADIUS * (this.num.someSinus / 5 + 1);

          // Update the singer tentacles
          const rect = this.getContainerClientRect();
          for (let i = 0; i < this.singerTentacles.length; ++i) {
            // Put tentacles all around the radius of the singer
            const theta = i * SINGER_TENTACLE_STEP;
            const px = Math.cos(theta) * this.num.singerDisplayRadius * 0.7;
            const py = Math.sin(theta) * this.num.singerDisplayRadius * 0.7;

            this.singerTentacles[i].move(
              this.num.singerX + px + rect.x + scrollY,
              this.num.singerY + py + rect.y + scrollY,
              false
            );
            this.singerTentacles[i].update();
          }

          let changeX = Math.sin(this.num.singerOverrideDisplayTimeX) * 1.5;
          let changeY = Math.sin(this.num.singerOverrideDisplayTimeY);

          if (changeX < 0) {
            if (this.num.singerX < SINGER_OVERRIDE_CLAMP_LEFT)
              changeX *= SINGER_OVERRIDE_SLOW_DOWN;
            else if (
              this.num.singerX < SINGER_OVERRIDE_CLAMP_RIGHT &&
              this.num.singerX > SINGER_OVERRIDE_CLAMP_MIDDLE
            )
              changeX *= SINGER_OVERRIDE_SPEED_UP;
          }
          if (changeX > 0) {
            if (this.num.singerX > SINGER_OVERRIDE_CLAMP_RIGHT)
              changeX *= SINGER_OVERRIDE_SLOW_DOWN;
            else if (
              this.num.singerX > SINGER_OVERRIDE_CLAMP_LEFT &&
              this.num.singerX < SINGER_OVERRIDE_CLAMP_MIDDLE
            )
              changeX *= SINGER_OVERRIDE_SPEED_UP;
          }
          if (changeY < 0) {
            if (this.num.singerY < SINGER_OVERRIDE_CLAMP_UP)
              changeY *= SINGER_OVERRIDE_SLOW_DOWN;
            else if (
              this.num.singerY < SINGER_OVERRIDE_CLAMP_DOWN &&
              this.num.singerY > SINGER_OVERRIDE_CLAMP_CENTER
            )
              changeY *= SINGER_OVERRIDE_SPEED_UP;
          }
          if (changeY > 0) {
            if (this.num.singerY > SINGER_OVERRIDE_CLAMP_DOWN)
              changeY *= SINGER_OVERRIDE_SLOW_DOWN;
            else if (
              this.num.singerY > SINGER_OVERRIDE_CLAMP_UP &&
              this.num.singerY < SINGER_OVERRIDE_CLAMP_CENTER
            )
              changeY *= SINGER_OVERRIDE_SPEED_UP;
          }

          this.num.singerX += changeX;
          this.num.singerY += changeY;
        }
      }

      if (!this.isActive || this.num.playerHealth <= 0) return;

      // Compute seconds elapsed since last frame
      const deltaTimeSec = (+new Date() - this.lastFrameTime) / 1000.0;
      this.lastFrameTime = +new Date();
      this.num.elapsedTime += deltaTimeSec;
      this.num.particleSimDelay += deltaTimeSec;

      // Skip frame if it took long to generate
      if (deltaTimeSec > 0.1) return;

      // Mise en commun des moyens de productions
      this.num.someSinus = Math.sin(this.num.elapsedTime * 5);

      // If out of focus, don't make the player move
      if (!document.hasFocus()) {
        this.keyStates.set("down", false);
        this.keyStates.set("up", false);
        this.keyStates.set("left", false);
        this.keyStates.set("right", false);
      }

      // Aura animation
      Bullet.auraScale = 1.1 + this.num.someSinus * AURA_SCALE_CHANGE;

      // Update particles and the singer on a fixed time frame (easier with the actual system)
      if (this.num.particleSimDelay > PARTICLE_SIM_SPEED) {
        if (this.isSingerFullyVisible() && !this.singerTouched) {
          // The singer pulse
          this.num.singerDisplayRadius =
            SINGER_RADIUS * (this.num.someSinus / 5 + 1);

          if (this.cannotCatchSinger === true) {
            // Move the singer depending if player is left or right
            this.num.singerX +=
              (this.isPlayerLeft ? 1 : -1) *
              Math.sin(
                (this.num.singerAnimation / this.num.currentSingerAnimation) *
                  Math.PI *
                  2
              ) *
              SINGER_X_MOVE_SPEED;
          } else {
            this.num.singerX +=
              (this.isPlayerLeft ? -1 : 1) * SINGER_X_MOVE_SPEED;
          }
          this.num.singerY =
            this.num.singerSpawnYPos +
            (this.isPlayerLeft ? 1 : -1) *
              Math.sin(
                (this.num.singerAnimation / this.num.currentSingerAnimation) *
                  Math.PI *
                  2
              ) *
              SINGER_Y_MOVE_DIST *
              50;

          // Update the singer tentacles
          const rect = this.getContainerClientRect();
          for (let i = 0; i < this.singerTentacles.length; ++i) {
            // Put tentacles all around the radius of the singer
            const theta = i * SINGER_TENTACLE_STEP;
            const px = Math.cos(theta) * this.num.singerDisplayRadius * 0.7;
            const py = Math.sin(theta) * this.num.singerDisplayRadius * 0.7;

            this.singerTentacles[i].move(
              this.num.singerX + px + rect.x + scrollY,
              this.num.singerY + py + rect.y + scrollY,
              false
            );
            this.singerTentacles[i].update();
          }
        }

        // Update particles
        this.num.particleSimDelay = 0;
        for (let i = this.particles.length - 1; i >= 0; --i) {
          let particle = this.particles[i];

          if (particle.alive) particle.move();
          else this.pool.push(this.particles.splice(i, 1)[0]);
        }
      }

      // Animate player diapason
      const target = this.diapIsLeft ? 1 : -1;
      const delta = Math.max(Math.abs(target - this.num.diapAnimation), 0.01);
      this.num.diapAnimation = this.bringTo(
        this.num.diapAnimation,
        target,
        DIAP_ANIMATION_SPEED * delta * deltaTimeSec
      );

      // Sample a diapason for visual effect
      if (
        this.num.elapsedTime - this.diapAnimHistoryLast >=
        DIAP_ANIM_HISTORY_STEP_MS
      ) {
        this.diapAnimHistoryPointer =
          (this.diapAnimHistoryPointer + 1) % DIAP_ANIM_HISTORY_LENGTH;
        this.diapAnimHistory[this.diapAnimHistoryPointer] =
          this.num.diapAnimation;
        this.diapAnimHistoryLast = this.num.elapsedTime;
      }

      // Do a cool heartbeat animation
      if (!this.singerTouched && this.num.playerHealth > 0) {
        this.num.playerDisplayRadius =
          PLAYER_RADIUS -
          PLAYER_RADIUS_CHANGE[this.num.playerHealth - 1] *
            heartBeatFunction(
              this.lastFrameTime * PLAYER_BEAT_SPEED[this.num.playerHealth - 1]
            );
      }

      // Cooldowns
      this.num.diapCoolDown = this.bringTo(
        this.num.diapCoolDown,
        0,
        deltaTimeSec
      );
      this.num.playerHurtCoolDown = this.bringTo(
        this.num.playerHurtCoolDown,
        0,
        deltaTimeSec
      );
      this.num.playerNoMoveCooldown = this.bringTo(
        this.num.playerNoMoveCooldown,
        0,
        deltaTimeSec
      );
      if (!this.singerTouched) {
        this.num.singerAnimation = this.bringTo(
          this.num.singerAnimation,
          0,
          deltaTimeSec
        );
      }
      this.num.clampAnimation = this.bringTo(
        this.num.clampAnimation,
        0,
        deltaTimeSec
      );

      // Update death circles
      for (let i = this.deathCircles.length - 1; i >= 0; --i) {
        this.deathCircles[i].lifetime = this.bringTo(
          this.deathCircles[i].lifetime,
          0,
          deltaTimeSec
        );

        // Slowly reduce radius
        this.deathCircles[i].radius -=
          DEATH_CIRCLE_RADIUS_DEPLETION * deltaTimeSec;

        // Remove death circle if lifetime is over
        if (this.deathCircles[i].lifetime === 0) {
          this.deathCircles.splice(i, 1);
          --i;
        }
      }

      // Handle diapason rotation
      if (this.num.diapCoolDown <= 0) {
        const differentSign =
          (this.num.diapCurrentAngle > 0 && this.num.playerMouseAngle < 0) ||
          (this.num.diapCurrentAngle < 0 && this.num.playerMouseAngle > 0);
        const goToOppositeDirection =
          differentSign && Math.abs(this.num.diapCurrentAngle) > Math.PI / 2;
        this.num.diapCurrentAngle = this.bringTo(
          this.num.diapCurrentAngle,
          this.num.playerMouseAngle,
          DIAP_MOVE_FORCE * deltaTimeSec * (goToOppositeDirection ? -1 : 1)
        );
        this.num.diapCurrentAngle = this.clampLoop(
          this.num.diapCurrentAngle,
          -Math.PI,
          Math.PI
        );
      }

      if (!this.singerTouched) {
        // Add bullets
        for (let i = this.patternStack.length - 1; i >= 0; --i) {
          // Too soon, no pattern to execute
          if (
            this.audioSources.music_background.getCurrentTime() <
            this.patternStack[i].time
          ) {
            break;
          }

          // Get flags
          this.patternFlags = this.patternStack[i].flags ?? {};

          // Execute pattern and remove it from the stack
          this.attackPatterns[this.patternStack[i].name](
            this.patternStack[i].options
          );
          this.patternStack.pop();
        }

        // Bullet sync beat animation
        let cosVal = Math.cos(
          (this.currTime() +
            this.battles[this.battleName].bulletBeatPhaseShift) *
            TWO_PI *
            this.battles[this.battleName].bulletBeatFreq *
            (this.num.playerHealth === 1 ? 2 : 1)
        );
        if (cosVal < 0) cosVal = 0;
        this.num.bulletDisplayScale = 1 + BULLET_RADIUS_CHANGE * cosVal;

        // Bullets update
        for (let i = 0; i < this.bullets.length; ++i) {
          this.bullets[i].update(deltaTimeSec);

          // Remove dead bullets
          if (this.bullets[i].lifetime < 0) {
            this.bullets.splice(i, 1);
            --i;
            continue;
          }

          // Kill bullets going out of the limits
          if (
            !this.bullets[i].isDying() &&
            ((this.bullets[i].x > TERRAIN_WIDTH + TERRAIN_BULLET_LIMIT &&
              this.bullets[i].vx > 0) ||
              (this.bullets[i].x < -TERRAIN_BULLET_LIMIT &&
                this.bullets[i].vx < 0) ||
              (this.bullets[i].y > TERRAIN_HEIGHT + TERRAIN_BULLET_LIMIT &&
                this.bullets[i].vy > 0) ||
              (this.bullets[i].y < -TERRAIN_BULLET_LIMIT &&
                this.bullets[i].vy < 0))
          ) {
            this.bullets[i].kill();
          }

          // Collision with player
          if (
            this.num.playerHurtCoolDown === 0 &&
            this.bullets[i].isActive() &&
            this.circle2Circle(
              this.bullets[i].x,
              this.bullets[i].y,
              Bullet.radius,
              this.num.playerX,
              this.num.playerY,
              PLAYER_COLLISION_RADIUS
            )
          ) {
            this.playerHurt();

            // Inertia when player hurt
            const dx = this.bullets[i].x - this.num.playerX;
            const dy = this.bullets[i].y - this.num.playerY;
            const playerBulletAngle = Math.atan2(dy, dx);
            this.num.playerVx =
              -Math.cos(playerBulletAngle) * PLAYER_HURT_INERTIA;
            this.num.playerVy =
              -Math.sin(playerBulletAngle) * PLAYER_HURT_INERTIA;
          }
        }
      }

      // Player update
      const oldPlayerX = this.num.playerX;
      const oldPlayerY = this.num.playerY;

      if (this.num.playerNoMoveCooldown == 0) {
        let playerSpeed = PLAYER_SPEED * deltaTimeSec;
        if (this.keyStates.get("left") === true) {
          this.num.playerX -= playerSpeed;
        }
        if (this.keyStates.get("right") === true) {
          this.num.playerX += playerSpeed;
        }
        if (this.keyStates.get("up") === true) {
          this.num.playerY -= playerSpeed;
        }
        if (this.keyStates.get("down") === true) {
          this.num.playerY += playerSpeed;
        }

        // Not used because it doesn't feel good
        /*
        // When the player goes diagonally, we want to have the same speed
        // as when going in a single direction so we normalize the speed
        if (
          oldPlayerX !== this.num.playerX &&
          oldPlayerY !== this.num.playerY
        ) {
          this.num.playerX +=
            (this.num.playerX - oldPlayerX) / Math.SQRT2 -
            (this.num.playerX - oldPlayerX);
          this.num.playerY +=
            (this.num.playerY - oldPlayerY) / Math.SQRT2 -
            (this.num.playerY - oldPlayerY);
        }
        */
      }

      // Handle player inertia
      this.num.playerX += this.num.playerVx;
      this.num.playerY += this.num.playerVy;

      this.num.playerVx = this.bringTo(
        this.num.playerVx,
        0,
        PLAYER_DRAG * deltaTimeSec
      );
      this.num.playerVy = this.bringTo(
        this.num.playerVy,
        0,
        PLAYER_DRAG * deltaTimeSec
      );

      this.clampPlayerPos();

      // Test if has moved
      if (oldPlayerX !== this.num.playerX || oldPlayerY !== this.num.playerY) {
        this.computePlayerMouseAngle();
      }
    },
    drawVerticalPartition(x: number, y: number) {
      // Draw 5 vertical lines like a musical partition
      for (let i = 0; i < 5; ++i) {
        this.ctx.beginPath();
        this.ctx.moveTo(x + i * PARTITION_LINE_SPACING, y);
        this.ctx.lineTo(x + i * PARTITION_LINE_SPACING, y + TERRAIN_HEIGHT);
        this.ctx.strokeStyle = "#" + this.battles[this.battleName].bulletColor;
        this.ctx.stroke();
        this.ctx.closePath();
      }
    },
    drawHorizontalPartition(x: number, y: number) {
      // Draw 5 vertical lines like a musical partition
      for (let i = 0; i < 5; ++i) {
        this.ctx.beginPath();
        this.ctx.moveTo(x, y + i * PARTITION_LINE_SPACING);
        this.ctx.lineTo(x + TERRAIN_WIDTH, y + i * PARTITION_LINE_SPACING);
        this.ctx.strokeStyle = "#" + this.battles[this.battleName].bulletColor;
        this.ctx.stroke();
        this.ctx.closePath();
      }
    },
    async draw() {
      const rect = this.getContainerClientRect();
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      if (this.overrideDisplaySinger) {
        const rect = this.getContainerClientRect();
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        this.ctx.globalAlpha = 1;

        // Draw the singer tentacles
        this.ctx.globalCompositeOperation = "lighter";
        for (let i = 0; i < this.singerTentacles.length; ++i) {
          this.singerTentacles[i].draw(this.ctx);
        }
        this.ctx.globalCompositeOperation = "source-over";

        // Draw the singer body
        this.ctx.beginPath();
        this.ctx.arc(
          this.num.singerX + rect.x + scrollX,
          this.num.singerY + rect.y + scrollY,
          this.num.singerDisplayRadius,
          0,
          Math.PI * 2
        );
        this.ctx.fillStyle = "#BC4BE5";
        this.ctx.fill();
        this.ctx.closePath();
        this.ctx.globalAlpha = 1;
      }

      if (!this.isActive) return;

      this.computePlayerGlobalPos(rect);

      this.ctx.globalAlpha =
        this.num.clampAnimation == 0
          ? 1
          : 1 - this.num.clampAnimation / CLAMP_ANIMATION_DURATION;

      if (this.clampDisapear) {
        this.ctx.globalAlpha = 1 - this.ctx.globalAlpha;
        if (this.ctx.globalAlpha == 0) {
          this.clampDisapear = false;
          this.num.clampLeft = 0;
          this.num.clampRight = 0;
          this.num.clampTop = 0;
          this.num.clampBottom = 0;
        }
      }
      // Draw partition clamp
      if (this.num.clampLeft !== 0) {
        this.drawVerticalPartition(
          this.num.clampLeft + rect.x + scrollX - 4 * PARTITION_LINE_SPACING,
          rect.y + scrollY
        );
      }
      if (this.num.clampRight !== 0) {
        this.drawVerticalPartition(
          this.num.clampRight + TERRAIN_WIDTH + rect.x + scrollX,
          rect.y + scrollY
        );
      }
      if (this.num.clampTop !== 0) {
        this.drawHorizontalPartition(
          rect.x + scrollX,
          this.num.clampTop + rect.y + scrollY - 4 * PARTITION_LINE_SPACING
        );
      }
      if (this.num.clampBottom !== 0) {
        this.drawHorizontalPartition(
          rect.x + scrollX,
          this.num.clampBottom + TERRAIN_HEIGHT + rect.y + scrollY
        );
      }
      this.ctx.globalAlpha = 1;

      // Draw death circles
      for (let i = this.deathCircles.length - 1; i >= 0; --i) {
        this.ctx.globalAlpha =
          1 -
          (DEATH_CIRCLE_LIFETIME - this.deathCircles[i].lifetime) /
            DEATH_CIRCLE_LIFETIME;
        this.ctx.beginPath();
        this.ctx.arc(
          this.deathCircles[i].x + rect.x + scrollX,
          this.deathCircles[i].y + rect.y + scrollY,
          this.deathCircles[i].radius,
          0,
          Math.PI * 2
        );
        this.ctx.fillStyle = "#ffffff";
        this.ctx.fill();
        this.ctx.closePath();
        this.ctx.globalAlpha = 1;
      }

      // Draw bullets
      for (let i = 0; i < this.bullets.length; ++i) {
        this.bullets[i].draw(rect);
      }

      // Draw particles
      for (let i = this.particles.length - 1; i >= 0; --i) {
        this.particles[i].draw(this.ctx);
      }

      if (this.showDebug) {
        // Draw mouse pos
        this.ctx.beginPath();
        this.ctx.arc(this.num.mouseX, this.num.mouseY, 5, 0, Math.PI * 2);
        this.ctx.fillStyle = "#00ff00";
        this.ctx.fill();
        this.ctx.closePath();

        for (let i = 0; i < this.bullets.length; ++i) {
          const x = this.bullets[i].x + rect.x + scrollX;
          const y = this.bullets[i].y + rect.y + scrollY;

          // Draw debug bullet radius
          this.ctx.beginPath();
          this.ctx.arc(x, y, Bullet.radius, 0, Math.PI * 2);
          this.ctx.fillStyle = "#00ff0022";
          this.ctx.fill();
          this.ctx.closePath();

          // Draw center of bullet
          this.ctx.beginPath();
          this.ctx.arc(x, y, 2, 0, Math.PI * 2);
          this.ctx.fillStyle = "#00ff00";
          this.ctx.fill();
          this.ctx.closePath();
        }
      }

      // Conditionnaly draw the singer
      if (this.isSingerFullyVisible()) {
        // Set the singer transparency
        if (this.num.singerAnimation < 1 && this.num.singerAnimation > 0) {
          this.ctx.globalAlpha = this.num.singerAnimation;
        } else if (
          this.num.currentSingerAnimation - this.num.singerAnimation <
          1
        ) {
          this.ctx.globalAlpha =
            this.num.currentSingerAnimation - this.num.singerAnimation;
        }

        // Draw the singer tentacles
        this.ctx.globalCompositeOperation = "lighter";
        for (let i = 0; i < this.singerTentacles.length; ++i) {
          this.singerTentacles[i].draw(this.ctx);
        }
        this.ctx.globalCompositeOperation = "source-over";

        // Draw the singer body
        this.ctx.beginPath();
        this.ctx.arc(
          this.num.singerX + rect.x + scrollX,
          this.num.singerY + rect.y + scrollY,
          this.num.singerDisplayRadius,
          0,
          Math.PI * 2
        );
        this.ctx.fillStyle = "#BC4BE5";
        this.ctx.fill();
        this.ctx.closePath();
        this.ctx.globalAlpha = 1;
      }

      // Draw diapason
      const angle =
        this.num.diapCoolDown > 0
          ? this.num.playerParryAngle
          : this.num.diapCurrentAngle;
      if (this.num.diapAnimation != -1 && this.num.diapAnimation != 1) {
        this.ctx.globalCompositeOperation = "lighter";
        for (let i = 0; i < DIAP_ANIM_HISTORY_LENGTH; ++i) {
          this.drawDiapason(
            this.diapIsLeft
              ? this.img_diapason_move_l
              : this.img_diapason_move_r,
            this.num.playerXGlobal,
            this.num.playerYGlobal,
            7.5,
            60.0,
            1.0,
            angle + Math.PI / 2,
            this.diapAnimHistory[i]
          );
        }
        this.ctx.globalCompositeOperation = "source-over";
      }
      this.drawDiapason(
        this.img_diapason,
        this.num.playerXGlobal,
        this.num.playerYGlobal,
        7.5,
        60.0,
        1.0,
        angle + Math.PI / 2,
        this.num.diapAnimation
      );

      // Handle player blink
      if (
        this.num.playerHurtCoolDown === 0 ||
        this.num.playerHurtCoolDown % 0.2 > 0.1
      ) {
        // Draw player body
        this.ctx.beginPath();
        this.ctx.arc(
          this.num.playerXGlobal,
          this.num.playerYGlobal,
          this.num.playerDisplayRadius,
          0,
          Math.PI * 2
        );
        if (this.num.playerHealth > 0) {
          this.ctx.fillStyle = PLAYER_COLOR[this.num.playerHealth - 1];
        } else {
          this.ctx.fillStyle = "#ff0000";
        }
        this.ctx.fill();
        this.ctx.closePath();

        // Draw player direction hint
        this.ctx.beginPath();
        this.ctx.moveTo(this.num.playerXGlobal, this.num.playerYGlobal);
        this.ctx.lineTo(
          this.num.playerXGlobal +
            Math.cos(angle) * this.num.playerDisplayRadius,
          this.num.playerYGlobal +
            Math.sin(angle) * this.num.playerDisplayRadius
        );
        this.ctx.strokeStyle = "#777";
        this.ctx.stroke();
      }

      if (this.showDebug) {
        // Draw player attack arc
        this.ctx.beginPath();
        this.ctx.moveTo(this.num.playerXGlobal, this.num.playerYGlobal);
        this.ctx.arc(
          this.num.playerXGlobal,
          this.num.playerYGlobal,
          Bullet.diap_range,
          this.num.playerMouseAngle - DIAP_ANGLE / 2,
          this.num.playerMouseAngle + DIAP_ANGLE / 2
        );
        this.ctx.lineTo(this.num.playerXGlobal, this.num.playerYGlobal);
        this.ctx.fillStyle = "#ff000055";
        this.ctx.fill();
        this.ctx.closePath();
      }
    },
    spawnParticle(
      px: number,
      py: number,
      options?: {
        color?: string;
        wander?: number;
        drag?: number;
        initialForce?: number;
        initialDirection?: number;
        radius?: number;
      }
    ) {
      const x = scrollX + px;
      const y = scrollX + py;

      let particle: Particle;

      if (this.particles.length >= MAX_PARTICLES)
        this.pool.push(this.particles.shift() as any);

      // radius
      const radius = options?.radius ? options.radius : randomFloat(5, 40);

      if (this.pool.length > 0) {
        particle = this.pool.pop() as Particle;
        particle.init(x, y, radius);
      } else {
        particle = new Particle(x, y, radius);
      }

      //wander
      particle.wander = options?.wander
        ? options.wander
        : randomFloat(0.5, 2.0);

      // color
      particle.color = options?.color
        ? options.color
        : COLOURS[randomInt(0, COLOURS.length)];

      // drag
      particle.drag = options?.drag ? options.drag : randomFloat(0.9, 0.99);

      // initial force
      const initialForce = options?.initialForce
        ? options.initialForce
        : randomFloat(2, 8);

      // initial direction
      const initialDirection = options?.initialDirection
        ? options.initialDirection
        : randomFloat(0, TWO_PI);

      particle.vx = Math.sin(initialDirection) * initialForce;
      particle.vy = Math.cos(initialDirection) * initialForce;

      this.particles.push(particle);
    },
  },
});
</script>

<style lang="scss">
@import "../assets/colors";
@import "../assets/vars";

.combat-container {
  width: 60em; // MUST BE IN SYNC WITH TERRAIN WIDTH
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  margin-top: 0.5em;
  background-color: $colorBg3;
  background: radial-gradient(
    ellipse at center,
    #202d3c 0%,
    #122335 40%,
    #000d19 100%
  );
  box-shadow: $boxShadow;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

.bg-img {
  pointer-events: none;
  user-select: none;
  animation-name: slowBlink;
  animation-duration: 30s;
  opacity: 0%;
}

@keyframes slowBlink {
  0% {
    opacity: 0%;
  }
  5% {
    opacity: 0%;
  }
  20% {
    opacity: 100%;
  }
  85% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

#combat-canvas {
  // Appear in front of everything
  overflow: visible;
  pointer-events: none;
  left: 0;
  top: 0;
  z-index: 500;
  position: absolute;
  transform-origin: center;
}

#blood-vignette {
  transition: 2s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 57%,
    rgba(212, 39, 23, 0.563) 90%
  );
  opacity: 1;
}

.debug-combat {
  bottom: -29%;
  position: absolute;
  background-color: black;
  color: coral;
  opacity: 0.6;
  z-index: 999;
  font-family: "Ubuntu Mono";
  display: flex;
  flex-wrap: wrap;
}
.debug-num {
  flex-basis: 32%;
}

.player-health {
  color: white;
  position: absolute;
  margin-left: -53.5em;
  margin-top: -14.5em;
  opacity: 0;
  transition-duration: 3s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  user-select: none;
}
</style>

<template>
  <div
    :style="`transition: ${durationMs}ms ${transFunction}; ${
      isShown ? shownStyle : hiddenStyle
    };`"
    @transitionend="isShown ? onShowEnd() : onHideEnd()"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

const emptyFunction = () => {};
export default defineComponent({
  name: "ShowHide",
  props: {
    isShown: {
      required: true,
      type: Boolean,
    },
    durationMs: {
      required: true,
      type: Number,
    },
    transFunction: {
      type: String,
      default: "ease",
    },
    hiddenStyle: {
      type: String,
      default: "opacity: 0%",
    },
    shownStyle: {
      type: String,
      default: "opacity: 100%",
    },
    onShowStart: {
      required: false,
      type: Function,
      default: () => emptyFunction,
    },
    onShowEnd: {
      required: false,
      type: Function,
      default: () => emptyFunction,
    },
    onHideStart: {
      required: false,
      type: Function,
      default: () => emptyFunction,
    },
    onHideEnd: {
      required: false,
      type: Function,
      default: () => emptyFunction,
    },
  },
  watch: {
    isShown(current, old) {
      if (current && !old) {
        this.onShowStart();
      }

      if (!current && old) {
        this.onHideStart();
      }
    },
  },
});
</script>

<style scoped lang="scss"></style>
